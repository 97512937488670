import React from 'react';
import './App.css';
import Calc from './components/layout/calc/calc';
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {MuiThemeProvider} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Layout from "./components/layout/layout";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Hind Siliguri',
            'sans-serif'
        ].join(','),
    },
});

function App() {
    return (
        <MuiThemeProvider theme={theme}>
           {/* <div className="App">*/}
            <React.Fragment>
                <CssBaseline/>
                <Layout>
                    <Calc/>
                </Layout>
            {/*</div>*/}
            </React.Fragment>
        </MuiThemeProvider>
    );
}

export default App;