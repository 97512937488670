import Container from "@material-ui/core/Container";
import {Grid, makeStyles, Paper} from "@material-ui/core";
import {Link, Redirect} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import React, {Fragment} from "react";



import "./GetUpFooter.css";
import {API_CONFIG} from "../../../config/api-config";
import SocialAccounts from './socialAccounts';
import Copyright from "./Copyright";


const useStyles = makeStyles(theme => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/user/erondu)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
    footer: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(6, 0),
        height: '100px',
        color: '#fff',
        fontSize: 10,

    },
}));
function GetUpFooter() {
    const classes = useStyles();

    return (

        <footer style={{backgroundColor:'#2d2d2d'}}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} >
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>OUR PRODUCTS</h3>

                            <a href={API_CONFIG.SHOPFRONT_URL.concat("product/getup-atom/")} target="_blank" rel="noopener noreferrer">GetUp Funeral Plan</a><br/>
                            <a href={API_CONFIG.SHOPFRONT_URL.concat("product/funeral-cover")} target="_blank" rel="noopener noreferrer">Metropolitan Funeral Plan</a><br/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>RESOURCES</h3>
                            <a href={API_CONFIG.SHOPFRONT_URL.concat("privacy-policy")} target="_blank" rel="noopener noreferrer">Privacy notice</a><br/>
                            <a href={API_CONFIG.SHOPFRONT_URL.concat("terms-of-use")} target="_blank" rel="noopener noreferrer">Terms of use</a><br/>
                            <a href={API_CONFIG.SHOPFRONT_URL.concat("transacting-online")} target="_blank" rel="noopener noreferrer">Transacting Online</a><br/>
                            <a href={API_CONFIG.SHOPFRONT_URL.concat("competition-terms-and-conditions")} target="_blank" rel="noopener noreferrer">Competition Terms and Conditions</a><br/>
                            <a href={`https://sacoronavirus.co.za`} target="_blank" rel="noopener noreferrer">Covid-19 resource centre</a>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>OUR FAMILY</h3>
                            <a href={`https://www.momentummetropolitan.co.za/en`} target="_blank"  rel="noopener noreferrer">MMH</a><br/>
                            <a href={`https://www.metropolitan.co.za`} target="_blank"  rel="noopener noreferrer">Metropolitan</a>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>ABOUT GETUP</h3>
                            <a href={API_CONFIG.SHOPFRONT_URL.concat("aboutus")} target="_blank" rel="noopener noreferrer">About us</a><br/>
                            <a href={API_CONFIG.SHOPFRONT_URL.concat("contact-us")} target="_blank" rel="noopener noreferrer">Contact us</a><br/>
                            <a href={`https://metrohelp.zendesk.com/hc/en-us`} target="_blank" rel="noopener noreferrer" >Get help</a>

                            <SocialAccounts />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <Copyright />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        </footer>
    );
}

export default GetUpFooter
