import React from 'react'
import {useEffect, useState} from "react";

import Accordion from '../accordion';
import Breakdown from '../breakdown';
import BreakdownButtons from '../subComponents/breakdownButtons.js';
import GetUpFooter from '../footer/GetUpFooter';
import Grid from '@material-ui/core/Grid';
import HowTo from '../subComponents/howTo/howTo.js';
import HowToMet from '../subComponents/howTo/howToMet.js';
import Navbar from './navbar';

import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import slogan from "../../../assets/images/slogan.png";
import strip from "../../../assets/images/strip.svg";
import circle from "../../../assets/images/circ.svg";
import arrowDownloadPlan from "../../../assets/images/arrow_download_plan.svg";

/*const useStyles = makeStyles({
    heading: {
        paddingBottom: 10,
        fontWeight: 700,
        fontSize: 35,
        textAlign: 'left',
    },
    subHeading: {
        fontSize: 14,
    },
    mobileSubHeading: {
        fontSize: 14,
        fontWeight: 550,
    },
    headerImages: {
        width: '25px',
        height: '25px',
        marginRight: '10px',
        color: 'rgba(0,0,0,0)'
    },
    deselectedHeaderImages: {
        width: '25px',
        height: '25px',
        marginRight: '10px',
        fill: 'rgba(76, 175, 80, 0.3)'
    },
    mobileAccordion: {
        /!*Use this to rearrange the position of the accordions in mobile view*!/
        order: 0,
    },
});*/

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Calc = (props) => {
    /* const styles = useStyles();*/
    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        cardGrid: {
            //paddingBottom: theme.spacing(2),
            //backgroundColor: 'white'
        },
        heading: {
            paddingBottom: 10,
            fontWeight: 700,
            fontSize: 35,
            textAlign: 'left',
            marginTop: 23
        },
        subHeading: {
            fontSize: 14,
        },
        mobileSubHeading: {
            fontSize: 14,
            fontWeight: 550,
            color: '#FFFFFF',
        },
        headerImages: {
            width: '25px',
            height: '25px',
            marginRight: '10px',
            color: 'rgba(0,0,0,0)'
        },
        deselectedHeaderImages: {
            width: '25px',
            height: '25px',
            marginRight: '10px',
            fill: 'rgba(76, 175, 80, 0.3)'
        },
        mobileAccordion: {

            order: 0,
        },
    }));
    const classes = useStyles();
    // const [budget, setBudget] = React.useState({
    //     budget: 0,
    //     used: 0,
    //     over: 0,
    // })

    // Pre-Funeral State Object
    // Holds the Total, individual values and checkbox values
    const [prefuneral, setPrefuneral] = React.useState({
        total: 0,
        repat: 0,
        undertaker: 0,
        cremation: 0,
        cemetery: 0,
        coffin: 0,
        urn: 0,
        arrangements: 0,
        livestock: 0,
        clothes: 0,
        repatCheck: false,
        undertakerCheck: false,
        cremationCheck: false,
        cemeteryCheck: false,
        coffinCheck: false,
        urnCheck: false,
        arrangementsCheck: false,
        livestockCheck: false,
        clothesCheck: false,
    });

    // Funeral State Object
    // Holds the Total, individual values and checkbox values
    const [funeral, setfuneral] = React.useState({
        total: 0,
        refreshment: 0,
        chairs: 0,
        catering: 0,
        funeService: 0,
        serviceVenue: 0,
        flowers: 0,
        programmes: 0,
        music: 0,
        refreshmentCheck: false,
        chairsCheck: false,
        cateringCheck: false,
        funeServiceCheck: false,
        serviceVenueCheck: false,
        flowersCheck: false,
        programmesCheck: false,
        musicCheck: false,
    });

    // Post-Funeral State Object
    // Holds the Total, individual values and checkbox values
    const [postfuneral, setpostfuneral] = React.useState({
        total: 0,
        tomb: 0,
        unveil: 0,
        cleans: 0,
        tombCheck: false,
        unveilCheck: false,
        cleansCheck: false,
    });

    // const handleBudget = ((event) => {
    //     setBudget({...budget, budget: (event.target.value === '') ? 0 : event.target.value});
    // });

    // const handleUsed = (() => {
    //     var used = parseInt(prefuneral.total) + parseInt(funeral.total) + parseInt(postfuneral.total);
    //     setBudget({...budget, used: used});
    // });


    // Adds up each value from Pre-Funeral and checks if checkbox value is true or false
    const getTotalpre = ((from, one, two, three, four, five, six, seven, eight, nine) => {
        var check = [one, two, three, four, five, six, seven, eight, nine];
        var total = 0;
        var x;
        for (x of check) {
            if (from[x + 'Check'] === true) {
                if (total === 0)
                    total = parseInt(from[x]);
                else
                    total += parseInt(from[x]);
            }
        }
        return (total);
    })

    // Adds up each value from Funeral and checks if checkbox value is true or false
    const getTotalfune = ((from, one, two, three, four, five, six, seven, eight) => {
        var check = [one, two, three, four, five, six, seven, eight];
        var total = 0;
        var x;
        for (x of check) {
            if (from[x + 'Check'] === true) {
                if (total === 0)
                    total = parseInt(from[x]);
                else
                    total += parseInt(from[x]);
            }
        }
        return (total);
    })

    // Adds up each value from Post-Funeral and checks if checkbox value is true or false
    const getTotalpost = ((from, one, two, three) => {
        var check = [one, two, three];
        var total = 0;
        var x;
        for (x of check) {
            if (from[x + 'Check'] === true) {
                if (total === 0)
                    total = parseInt(from[x]);
                else
                    total += parseInt(from[x]);
            }
        }
        return (total);
    })

    //  Update a value from Pre-Funeral
    const handleTotalPre = ((event, value) => {
        setPrefuneral({...prefuneral, [event.target.name]: (value === '') ? 0 : value});
    });

    // Gets the current total of Pre-Funeral and checks it's current state value, and updates if the values differ
    if (prefuneral.total !== (getTotalpre(prefuneral, "repat", "cremation", "undertaker", "cemetery", "coffin", "urn", "arrangements", "livestock", "clothes"))) {
        setPrefuneral({
            ...prefuneral,
            total: getTotalpre(prefuneral, "repat", "cremation", "undertaker", "cemetery", "coffin", "urn", "arrangements", "livestock", "clothes")
        });
    }

    //  Update a value from Funeral
    const handleTotalFune = ((event, value) => {
        setfuneral({...funeral, [event.target.name]: (value === '') ? 0 : value});
    });

    // Gets the current total of Funeral and checks it's current state value, and updates if the values differ
    if (funeral.total !== (getTotalfune(funeral, "refreshment", "chairs", "catering", "funeService", "flowers", "programmes", "music", "serviceVenue"))) {
        setfuneral({
            ...funeral,
            total: getTotalfune(funeral, "refreshment", "chairs", "catering", "funeService", "flowers", "programmes", "music", "serviceVenue")
        });
    }

    //  Update a value from Post-Funeral
    const handleTotalPost = ((event, value) => {
        setpostfuneral({...postfuneral, [event.target.name]: (value === '') ? 0 : value});
    });

    // Gets the current total of Post-Funeral and checks it's current state value, and updates if the values differ
    if (postfuneral.total !== (getTotalpost(postfuneral, "tomb", "unveil", "cleans"))) {
        setpostfuneral({...postfuneral, total: getTotalpost(postfuneral, "tomb", "unveil", "cleans")});
    }
    ;

    // if ((parseInt(prefuneral.total) + parseInt(funeral.total) + parseInt(postfuneral.total)) !== parseInt(budget.used)){
    //     handleUsed();
    //     console.log("set used");
    // };

    // if (!(parseInt(budget.used) <= parseInt(budget.budget)) && (parseInt(budget.over) !== (parseInt(budget.used) - parseInt(budget.budget)))) {
    //     setBudget({...budget, over: (parseInt(budget.used) - parseInt(budget.budget))});
    //     console.log("set over");
    //     console.log(budget);
    // };

    // Set the checkbox state for Pre-Funeral
    const handleCheckedPre = (event) => {
        setPrefuneral({...prefuneral, [event.target.name]: event.target.checked});
    };

    // Set the checkbox state for Funeral
    const handleCheckedFune = (event) => {
        setfuneral({...funeral, [event.target.name]: event.target.checked});
    };

    // Set the checkbox state for Post-Funeral
    const handleCheckedPost = (event) => {
        setpostfuneral({...postfuneral, [event.target.name]: event.target.checked});
    };


    // Values for each item in each section should be set here.
    // Pre-Funeral Object
    var pref = [
        // cName holds checkbox state value, and name is used to access state value
        {
            id: 1,
            icon: require('../../../icons/repatriation.svg'),
            title: 'Repatriation',
            avg: '15 000',
            range: 'Usually between R5 000 to R25 000',
            val: prefuneral.repat,
            cName: prefuneral.repatCheck,
            name: 'repat',
            desc: 'Moving of the deceased body inside or outside South Africa to the place of the burial.\nNote: A repatriation benefit is built into this plan'
        },
        {
            id: 2,
            icon: require('../../../icons/undertaker.svg'),
            title: 'Undertaker',
            avg: '20 000',
            range: 'Usually between R3 000 to R45 000',
            val: prefuneral.undertaker,
            cName: prefuneral.undertakerCheck,
            name: 'undertaker',
            desc: 'The cost of storing and the preparation of the body'
        },
        {
            id: 3,
            icon: require('../../../icons/cremation.svg'),
            title: 'Cremation',
            avg: '2 000',
            range: 'Usually between R1 500 to R2 500',
            val: prefuneral.cremation,
            cName: prefuneral.cremationCheck,
            name: 'cremation',
            desc: 'The cost of transforming the deceased body to ashes'
        },
        {
            id: 4,
            icon: require('../../../icons/cemetery.svg'),
            title: 'Cemetery',
            avg: '3 000',
            range: 'Usually between R2 500 to R4 000',
            val: prefuneral.cemetery,
            cName: prefuneral.cemeteryCheck,
            name: 'cemetery',
            desc: 'The cost of the burial of the body'
        },
        {
            id: 5,
            icon: require('../../../icons/coffin.svg'),
            title: 'Coffin/Casket',
            avg: '12 000',
            range: 'Usually between R700 to R25 000',
            val: prefuneral.coffin,
            cName: prefuneral.coffinCheck,
            name: 'coffin',
            desc: 'The cost of purchasing a coffin/casket to lay the deceased to rest'
        },
        {
            id: 6,
            icon: require('../../../icons/urn.svg'),
            title: 'Urn',
            avg: '400',
            range: 'From R200 to R800',
            min: "200",
            max: "800",
            val: prefuneral.urn,
            cName: prefuneral.urnCheck,
            name: 'urn',
            desc: 'The cost of urn for keeping and/or burying the ashes '
        },
        {
            id: 7,
            icon: require('../../../icons/funeral-arrangement.svg'),
            title: 'Funeral Arrangements',
            avg: '750',
            range: 'Usually between R200 to R1 500',
            max: "1500",
            val: prefuneral.arrangements,
            cName: prefuneral.arrangementsCheck,
            name: 'arrangements',
            desc: 'This may include the collection of the deceased body, storage, death registration, undertaker attendance fees and admin fees'
        },
        {
            id: 8,
            icon: require('../../../icons/livestock.svg'),
            title: 'Livestock',
            avg: '7 500',
            range: 'Usually between R1 000 to R15 000',
            val: prefuneral.livestock,
            cName: prefuneral.livestockCheck,
            name: 'livestock',
            desc: 'Goat, sheep and cow that is slaughtered as part of the funeral ceremony'
        },
        {
            id: 9,
            icon: require('../../../icons/clothes.svg'),
            title: 'Clothes',
            avg: '1 500',
            range: 'Usually between R500 to R3 000',
            val: prefuneral.clothes,
            cName: prefuneral.clothesCheck,
            name: 'clothes',
            desc: 'Purchasing clothing for the funeral ceremony'
        },
    ];

    // Funeral Object
    var fune = [
        // cName holds checkbox state value, and name is used to access state value
        {
            icon: require('../../../icons/service-venue.svg'),
            title: 'Service Venue',
            avg: '10 000',
            range: 'Usually between R400 to R20 000',
            val: funeral.serviceVenue,
            cName: funeral.serviceVenueCheck,
            name: 'serviceVenue',
            desc: 'The cost of the venue where the funeral service will be held e.g. church, hall and stadium'
        },
        {
            icon: require('../../../icons/chairs.svg'),
            title: 'Chairs',
            avg: '15',
            range: 'Usually between R10 to R30',
            val: funeral.chairs,
            cName: funeral.chairsCheck,
            name: 'chairs',
            desc: 'The cost of the seating to be placed in the service venue'
        },
        {
            icon: require('../../../icons/catering.svg'),
            title: 'Catering',
            avg: '5 000',
            range: 'Usually between R3 000 to R10 000',
            val: funeral.catering,
            cName: funeral.cateringCheck,
            name: 'catering',
            desc: 'The cost of food to be served to the funeral guests, this could include tea and snacks or a full meal'
        },
        {
            icon: require('../../../icons/funeral-arrangement.svg'),
            title: 'Funeral Service',
            avg: '1 500',
            range: 'Usually between R500 to R3 000',
            val: funeral.funeService,
            cName: funeral.funeServiceCheck,
            name: 'funeService',
            desc: 'The cost of the ceremony for the final send-off, either through cremation or burial'
        },
        {
            icon: require('../../../icons/flowers.svg'),
            title: 'Flowers',
            avg: '5 000',
            range: 'Usually between R3 000 to R10 000',
            val: funeral.flowers,
            cName: funeral.flowersCheck,
            name: 'flowers',
            desc: 'The cost of the flowers to be placed at the service and on top of the coffin/casket'
        },
        {
            icon: require('../../../icons/programmes.svg'),
            title: 'Programs',
            avg: '450',
            range: 'Usually between R400 to R900',
            val: funeral.programmes,
            cName: funeral.programmesCheck,
            name: 'programmes',
            desc: 'The cost of printing the programs that will be distributed to attendees'
        },
        {
            icon: require('../../../icons/music.svg'),
            title: 'Music',
            avg: '3 750',
            range: 'Usually between R300 to R7 500',
            val: funeral.music,
            cName: funeral.musicCheck,
            name: 'music',
            desc: 'The cost of hiring a band, instrumentalist, pianist or choir to perform at the funeral service'
        },
    ];

    // Post-Funeral Object
    const postf = [
        // cName holds checkbox state value, and name is used to access state value
        {
            icon: require('../../../icons/tombstone.svg'),
            title: 'Tombstone',
            avg: '7 500',
            range: 'Usually between R 1500 to R15 000',
            val: postfuneral.tomb,
            cName: postfuneral.tombCheck,
            name: 'tomb',
            desc: 'The cost of purchasing a tombstone'
        },
        {
            icon: require('../../../icons/unveiling.svg'),
            title: 'Unveiling Ceremony',
            avg: '7 500',
            range: 'Usually between R5 000 to R15 000',
            val: postfuneral.unveil,
            cName: postfuneral.unveilCheck,
            name: 'unveil',
            desc: 'The grave cost, removal and replacement of tombstone, equipment and labour'
        },
        {
            icon: require('../../../icons/cleansing.svg'),
            title: 'Cleansing Ceremony',
            avg: '7 500',
            range: 'Usually between R3 000 to R15 000',
            val: postfuneral.cleans,
            cName: postfuneral.cleansCheck,
            name: 'cleans',
            desc: 'This may include food, livestock and travel costs for family members to attend the cleansing ceremony'
        },
    ];

    /*Re-using from Breakdown*/
    const [isDesktop, setDesktop] = useState(window.innerWidth > 599);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = (media) => {
        setOpen(!open);
    };

    const handleMetClose = (media) => {
        setOpen(!open);
    };

    const updateMedia = () => {
        setDesktop(window.innerWidth > 599);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    const [id, setId] = useState("");
    const host = window && window.location && window.location.hostname;
    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        setId(params.get('id'));
        console.log("ID STATUS --->" + id)


    })
  console.log("HOST ----> " + host)
    const routeToFun = () => {

        if((host === 'www.funeralplannermetgetup.co.za') || (host === 'www.funeralplanner.metgetup.co.za')) {
            window.open("https://www.metropolitaninsurance.co.za/?utm_id=100009&utm_source=direct&utm_medium=&utm_content=MFPFuneralPlanner&utm_source_platform=web&utm_creative_format=button&utm_marketing_tactic=lead_generation", "_blank");
        }
        else
        {
            window.open("https://develop.d3m9egys5fvkyp.amplifyapp.com/?utm_id=100009&utm_source=direct&utm_medium=&utm_content=MFPFuneralPlanner&utm_source_platform=web&utm_creative_format=button&utm_marketing_tactic=lead_generation", "_blank");
        }
    }

    return (
        /* <div className={styles.mainContainer}>*/
        <div>
            <Container className={"mainContentWrapper"}>
                {(id != 'met') ? (
                    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}
                            scroll="paper" maxWidth='sm' fullWidth={true}>
                        <HowTo handleClose={handleClose} ref={descriptionElementRef}/>
                    </Dialog>
                ) : (
                    <Dialog open={open} onClose={handleMetClose} TransitionComponent={Transition}
                            scroll="paper" maxWidth='sm' fullWidth={true}>
                        <HowToMet handleClose={handleMetClose} ref={descriptionElementRef}/>
                    </Dialog>)}

                {/* <Navbar />*/}
                <Grid container direction="column">
                    <Grid item container
                          direction='row' spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={10}>
                            {/* Page Title */}


                            {/* Introduction */}
                            {isDesktop ? (

                                <React.Fragment>
                                    <div>
                                        {(id != 'met') ? (""

                                        ) : ("")}<br/>
                                        {(id === 'met') ? (
                                            <span id="getquote">

                                         <img src={strip} className="strip"/> GET A QUOTE
                                    </span>
                                        ) : (<span className={"headingText"}> Funeral Event Planner   </span>)}
                                        <Typography style={{flexGrow: 1, paddingTop: 0}} className="slogan">
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography align="left" className={"subheadingText"}> There are many
                                            costs related to a funeral and they come at a time when you least expect
                                            it.</Typography>
                                        <Typography align="left" className={"subheadingText"}>This Funeral
                                            Event Planner will help you estimate the cost to fund a dignified burial for
                                            you
                                            loved ones or yourself.</Typography>
                                        {(id != 'met') ? (
                                            <Typography align="left" className={"subheadingText"}>Not sure how it
                                                all works? <a style={{color: '#4353ff', cursor: 'pointer'}}
                                                              onClick={handleClose}>Need help?</a> <br/></Typography>
                                        ) : (
                                            <Typography align="left" className={"subheadingText"}>Not sure how it
                                                all works? <a style={{color: '#4353ff', cursor: 'pointer'}}
                                                              onClick={handleMetClose}>Need help?</a> <br/></Typography>
                                        )}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div>
                                    <div>
                                        {(id != 'met') ? (
                                            <span className="funeralPlannerHeading">
                                                 Funeral Event Planner
                                            </span>
                                        ) : ("")}<br/><br/>
                                        {(id === 'met') ? (
                                            <span id="getquote">
                                                <img src={strip} className="strip"/> GET A QUOTE
                                             </span>
                                        ) : ("")}

                                    </div>
                                    <Typography style={{flexGrow: 1, paddingTop: 0}} className="slogan">
                                    </Typography>
                                    <Typography align="left" className={"subheadingText"}> There are
                                        many costs related to a funeral and they come at a time when you least expect
                                        it.
                                        This Funeral Event Planner will help you estimate the cost to fund a dignified
                                        burial for you loved ones or yourself.</Typography>

                                    {(id != 'met') ? (
                                        <Typography align="left" variant="h6" className={classes.mobileSubHeading}>Not
                                            sure
                                            how it all works? <a style={{color: '#e5fe04', cursor: 'pointer'}}
                                                                 onClick={handleClose}>Need help?</a><br/>
                                            <br/></Typography>

                                    ) : (
                                        <Typography align="left" className={"subheadingText"}>Not sure how it
                                            all works? <a style={{color: 'blue', cursor: 'pointer'}}
                                                          onClick={handleMetClose}>Need help?</a><br/>
                                            <br/></Typography>
                                    )}
                                </div>
                            )
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} className={isDesktop ? '' : classes.mobileAccordion}>
                            <Accordion
                                // Pre-Funeral state and functions
                                prefuneral={prefuneral} handleCheckedPre={handleCheckedPre}
                                handleTotalPre={handleTotalPre}
                                // Funeral state and functions
                                funeral={funeral} handleCheckedFune={handleCheckedFune}
                                handleTotalFune={handleTotalFune}
                                // Post-Funeral state and functions
                                postfuneral={postfuneral} handleCheckedPost={handleCheckedPost}
                                handleTotalPost={handleTotalPost}

                                // Pre/Post/funeral Data Objects
                                pref={pref} fune={fune} postf={postf}

                                // budget={budget} handleBudget={handleBudget}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Paper elevation={3}>
                                <Breakdown
                                    // State and Data Objects
                                    prefuneral={prefuneral}
                                    funeral={funeral}
                                    postfuneral={postfuneral}
                                    pref={pref}
                                    fune={fune}
                                    postf={postf}/>
                            </Paper>
                            <br/>
                            {(id == 'met') ? (
                                <Paper elevation={3}>
                                    <Typography variant={"h4"} component={"h4"} className={"BreakdownHead"}>
                                        Need cover?
                                    </Typography>
                                    <div className={"GetQuote"}>
                                        <p>Now that you know just how much you need, why not cover yourself and your family under one comprehensive funeral plan? Buy Metropolitan funeral cover online and join millions of South Africans that have trusted us for over 125 years. Leading value-added benefits, simple and quick claims process that pays out in hours, our cover is there for you when you need it most. </p>
                                            <button
                                            className="MuiButtonBase-root MuiButton-root MuiButton-contained productButton closeDisclaimerButton"
                                            tabIndex="0" type="button"
                                            onClick={routeToFun}
                                            >

                                                <span
                                            className="MuiButton-label-close">&nbsp; Get a quote <span
                                        ><img src={circle} className={"circleArrow"}/>


                                </span></span><span
                                            className="MuiTouchRipple-root MuiButton-iconSizeMedium"></span></button>
                                        <img src={arrowDownloadPlan} className={"arrowGoToQuote"}/>
                                    </div>

                                </Paper>) : ("")}
                        </Grid>


                    </Grid>

                </Grid>
                {!isDesktop &&

                    <BreakdownButtons
                        // Pre-Funeral state and functions
                        prefuneral={prefuneral} handleCheckedPre={handleCheckedPre} handleTotalPre={handleTotalPre}
                        // Funeral state and functions
                        funeral={funeral} handleCheckedFune={handleCheckedFune} handleTotalFune={handleTotalFune}
                        // Post-Funeral state and functions
                        postfuneral={postfuneral} handleCheckedPost={handleCheckedPost}
                        handleTotalPost={handleTotalPost}

                        // Pre/Post/funeral Data Objects
                        pref={pref} fune={fune} postf={postf}
                    />
                }
            </Container>

        </div>

    )
}

export default Calc
