import React from 'react'
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import './styles.css';
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {useEffect, useState} from "react";


import { useParams } from "react-router";
import { Route, Switch, Link } from "react-router-dom";


const useStyles = makeStyles({
    input: {
        height: 40,
        width: "100%"
    },
    container: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 0,
    },
    checkbox: {
        marginLeft: 0,
    },
    popover: {
        padding: 10,
        shadows: "none",
        background: 'white'
    },


    ActivityTitle: {
       paddingLeft: 16,
        fontFamily: 'Hind Siliguri Bold',
        fontWeight: 'bold'
    },
    mobileContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
    },
    mobileSubContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '80%'
    }
});

const Prefuneral = (props) => {
    // const rows = [


    const classes = useStyles();

    // Popover anchor position and open state
    const [openPop, setOpenPop] = React.useState({
        anchor: null,
        repatO: false,
        undertakerO: false,
        cremationO: false,
        cemeteryO: false,
        coffinO: false,
        urnO: false,
        arrangementsO: false,
        livestockO: false,
        clothesO: false,
    });

    //Handle Popover click
    const handleClick = (name, event) => {
        setOpenPop({...openPop, [name + 'O']: Boolean(event.currentTarget), anchor: event.currentTarget});
    };

    //Handle Popover close
    const handleClose = (name, event) => {
        setOpenPop({...openPop, [name]: false});
    };


    const [isDesktop, setDesktop] = useState(window.innerWidth > 599);
    const [variantVal, setVariantVal] = useState(true);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 599);
    };

   // const [id, setId] = useState("");
    const [id, setId] = useState("");

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setId(params.get('id'));
        if (id === "met") {
            setVariantVal(true)
        }


       // alert("setVariantVal : "+variantVal)
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    })


    useEffect(() => {
        window.addEventListener("beforeunload", function(e){
            yourCustomFunction();
        });

    });

    function yourCustomFunction(){

    }
    return (
        <React.Fragment>
            {isDesktop ? (
                <Grid container className={"accordionContent"} spacing={1}>
                    <Grid container direction="row" justify="space-evenly"
                          className={classes.container}>
                        <Grid item xs={1} className={"title"} justify="center"
                              alignItems="left">
                            <Typography className={"title"}   align="left">Select</Typography>
                        </Grid>
                        <Grid item xs={6}  align="left">
                            <Typography className={"title"} align="center">Item/Activity</Typography>
                        </Grid>

                        {/*<Grid item xs={6} sm={3}>
                            <Typography className={classes.title} align="left">Average cost</Typography>
                        </Grid>*/}
                        <Grid item xs={5} align="left">
                            <Typography className={"title"} align="right">How much would you<br/> like to
                                spend?</Typography>
                        </Grid>
                    </Grid>
                    <hr className={"headerDivider"}/>
                    {/* Display each item in the pre-funeral state object */}
                    {props.rows.map((row, i) => (
                        <Grid container direction="row" justify="space-evenly" justify="center" alignItems="center"
                              className="accordionGridItemStyle">
                            <Grid item xs={1} className={classes.icon} justify="center"
                                  alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.prefuneral[row.name + 'Check']}
                                            onChange={props.handleChecked}
                                            name={row.name + 'Check'}
                                            color="primary"
                                            labelStyle={{color: 'white'}}
                                            iconStyle={{fill: 'white'}}
                                            inputStyle={{color:'white'}}
                                            style={{color:'white'}}
                                        />
                                    }

                                    label=""/>
                            </Grid>
                            <Grid item xs={6} sm container>
                                <Grid item xs container direction="row" justify="center" alignItems="center" >
                                    <Grid item xs={2} justify="center" alignItems="center" className={"gridIconContainer"}>
                                        <img id={`img_${row.title}`} src={row.icon} title='1' description='1' width={25}
                                             height={25}
                                             alt={row.title}/>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <IconButton onClick={e => handleClick(row.name, e)}>
                                        <Typography className={"title itemActivity"} align="left">{row.title}</Typography>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={e => handleClick(row.name, e)}>
                                            <InfoIcon className={"MuiSvgIcon-root-blue"}/>
                                        </IconButton>
                                        <Popover
                                            id={row.id}
                                            open={openPop[row.name + 'O']}
                                            anchorEl={openPop.anchor}
                                            onClose={e => handleClose(row.name + 'O', e)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Typography className={classes.popover}>{row.desc}</Typography>
                                        </Popover>

                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={6} sm={3}>
                                <Typography align="left">R{row.avg}</Typography>
                            </Grid>*/}
                            <Grid item xs={5} align="left">
                                {/* props.handleTotal -> referenced from calc line 143*/}
                                <Tooltip title={row.range} placement="top">
                                    <div>
                                        <CurrencyTextField
                                            variant={variantVal ? "standard" : "outlined"}
                                            value={props.prefuneral[row.name]}
                                            name={row.name}
                                            className={classes.input}
                                            currencySymbol="R"
                                            minimumValue="0"
                                            decimalCharacter="."
                                            digitGroupSeparator=" "
                                            decimalPlacesShownOnFocus='0'
                                            size="small"
                                            disabled={!props.prefuneral[row.name + 'Check']}
                                            onChange={(event, value) => props.handleTotal(event, value)}
                                            helperText={row.range}
                                            modifyValueOnWheel={false}
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>

                        </Grid>
                    ))}
                </Grid>
            ) : (

                <Grid container direction="row" spacing={1} className={"accordionContent"} >
                    {/* Display each item in the pre-funeral state object */}
                    {props.rows.map((row,i) => (
                        <React.Fragment>
                        <Grid container direction="column" spacing={1} className="accordionGridItemStyle"
                        >
                            <Grid container direction="row" alignItems="center" className={classes.container}>
                                <Grid item float="center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                 checked={props.prefuneral[row.name + 'Check']}
                                                onChange={props.handleChecked}
                                                name={row.name + 'Check'}
                                                labelStyle={{color: 'white'}}
                                                iconStyle={{fill: 'white'}}
                                                inputStyle={{color:'white'}}
                                                style={{color:'white'}}

                                            />
                                        }
                                        style={{float: "center", marginLeft: "15%"}}
                                        label=""/>
                                </Grid>
                                <div className={classes.mobileSubContainer} >
                                    <Grid item xs={4} sm={1} className={classes.icon} align="center">
                                        <img id={1} src={row.icon} title='1' description='1' width={25} height={25}
                                             alt={row.title}/>
                                    </Grid>
                                    <Grid item xs={5} sm={3}>
                                        <Typography className={"title"} align="center">{row.title}</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={1} align="center">
                                        <IconButton onClick={e => handleClick(row.name, e)}>
                                            <InfoIcon color="primary"/>
                                        </IconButton>
                                        <Popover
                                            id={row.id}
                                            open={openPop[row.name + 'O']}
                                            anchorEl={openPop.anchor}
                                            onClose={e => handleClose(row.name + 'O', e)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Typography className={classes.popover}>{row.desc}</Typography>
                                        </Popover>
                                    </Grid>
                                </div>

                            </Grid>

                            <Grid container direction="row" alignItems="center" spacing={1}
                                  className={"paddingTopAndBottom"}
                                  style={{justifyContent: "center"}}>

                                <Grid item xs={12} align="center">
                                    <Tooltip title={row.range} placement="top">
                                        <div>
                                            <CurrencyTextField
                                                variant={variantVal? "standard":"outlined"}
                                                value={props.prefuneral[row.name]}
                                                name={row.name}
                                                className={classes.input}
                                                label={"How much do you need?"}
                                                currencySymbol="R"
                                                outputFormat="string"
                                                minimumValue="0"
                                                decimalCharacter="."
                                                digitGroupSeparator=" "
                                                decimalPlacesShownOnFocus='0'
                                                size="small"
                                                disabled={!props.prefuneral[row.name + 'Check']}
                                                onChange={(event, value) => props.handleTotal(event, value)}
                                                helperText={row.range}
                                                modifyValueOnWheel={false}
                                            />
                                        </div>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                            {(props.rows.length !== (i+1)) &&( <hr className={"mainDivider"}/>)
                            }

                        </React.Fragment>
                    ))}
                </Grid>
            )
            }
        </React.Fragment>

    )
}

export default Prefuneral
