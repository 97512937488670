import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {makeStyles} from '@material-ui/core/styles';

import "./layout.css"
import GetUpFooter from "./navigation/GetUpFooter";
import MetFooter from "./navigation/MetFooter";
import Navigation from "./navigation/Navigation";
import MetNavigation from "./navigation/MetNavigation";

const useStyles = makeStyles(theme => ({
    /*root: {backgroundColor: '#fafafa'},*/

}));

const Layout = ({children}) => {
    //  let id

    const classes = useStyles();
    const [id, setId] = useState("");
    useEffect(() => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            setId(params.get('id'));

              if (id === "met") {
                    console.log("ID IS MET :-----> " + id);
                     import('../../assets/css/met-style.css').then(() => {});
              }
              if (id === undefined || id === null){
                  console.log("ID IS STYLE :-----> " + id);
                    import('../../assets/css/style.css').then(() => {});
              }
   })

    return (
        <React.Fragment>
            {/*header nav*/}

            {(id != 'met') ? (
                    <React.Fragment>
                        <Navigation/>
                    </React.Fragment>
                ) :
                (<React.Fragment>
                        <MetNavigation/>
                    </React.Fragment>
                )}


            <main className={classes.mainContainer}>{children}</main>

            {(id != 'met') ? (
                    <React.Fragment>
                        <React.Fragment>
                            <React.Fragment>
                                <div className={"mobile-bottom"}></div>
                            </React.Fragment>
                        </React.Fragment>
                        <GetUpFooter/>
                    </React.Fragment>
                ) :
                (<React.Fragment>
                        <React.Fragment>
   <br/>
                        </React.Fragment>

                        <MetFooter/>
                    </React.Fragment>
                )}

        </React.Fragment>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
