import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Prefuneral from '../funeral/prefuneral';
import Funeral from '../funeral/funeral';
import Postfuneral from '../funeral/postfuneral';
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

    heading: {
        height: 20,
        color: '#FFFFFF',
        fontSize: 14,
        fontFamily: ['HindSiliguri-SemiBold', 'Hind Siliguri SemiBold', 'Hind Siliguri', 'sans-serif'],
        fontWeight: 650,
    },
    secondaryHeading: {
        display: 'flex',
        flexFlow: 'nowrap row',
        alignItems: 'center',
        marginRight: 10,
        width: '30%',
        justifyContent: 'flex-end',
        color: '#FFFFFF',
    },
    total: {
        fontSize: 24,
        fontFamily: ['HindSiliguri-SemiBold', 'Hind Siliguri SemiBold', 'Hind Siliguri', 'sans-serif'],
        fontWeight: 650
    },
    headerImagesContainer: {
        flexBasis: '100%',
        display: 'flex',
        width: '100%',
        color: '#FFFFFF'
    },
    headerImages: {
        width: '25px',
        height: '25px',
        marginRight: '10px',
    },
    deselectedHeaderImages: {
        width: '25px',
        height: '15px',
        marginTop: '12px',
        opacity: 0.5,
        color:'#FFFFFF'
    }
}));

export default function ControlledAccordions(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState("panel2");
    const [id, setId] = useState("");

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setId(params.get('id'));
        console.log("ACCORDION ID : " + id);
   })


    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    //Expand Accordion panel
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (panel === "panel2") {
            window.scroll({top: 150, left: 0, behavior: 'smooth'});
        }
        if (panel === "panel3") {
            window.scroll({top: 265, left: 0, behavior: 'smooth'});
        }
        if (panel === "panel4") {
            window.scroll({top: 375, left: 0, behavior: 'smooth'});
        }
    };

    const executeScroll = (componentName) => document.querySelector('div[name="' + componentName + '"').scrollIntoView({
        behavior: 'smooth'
    });

    return (
        <Grid container justify="space-between" direction="row">
            <Grid item md={12} xs={12} className="gridItemStyle">
                {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Budget</Typography>
  <Typography className={classes.secondaryHeading}> Used: R{props.budget.used} </Typography> <Typography className={classes.secondaryHeading}> Over: R{props.budget.over}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Details budget={props.budget} handleBudget={props.handleBudget}/>
        </AccordionDetails>
      </Accordion> */}

                {/*Pre-Funeral accordion*/}
                <Accordion className="gridItemStyle firstAccordionOutline" expanded={expanded == 'panel2'} onChange={handleChange('panel2')}
                            >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={"MuiSvgIcon-root1"}/>}
                        name='panel2'
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"

                        className="firstAccordionSummary">
                        <div
                            style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                                justifyContent: 'space-between',
                                width: '100%',
                                height: '40px',
                                fontSize:'26px',
                            }}>
                            {/*Heading*/}

                            {(id=='met') ? (
                            <div>
                                <Typography className={"accordHeading"}>Pre-funeral</Typography>
                            </div>):(
                                <Typography className={"accordHeading"}>PRE-FUNERAL</Typography>
                            )}

                            {/*Total*/}
                            <div>
                                <Typography
                                    className={"accordHeading"}>R{formatNumber(props.prefuneral.total)}</Typography>
                            </div>
                            {/*Images*/}
                            {(expanded !== 'panel2') && <div style={{flexBasis: '100%',zIndex:'-1'}}>
                                {props.pref.map((row) => (
                                    <img id={11} src={row.icon} title='1' description='1'
                                         className={(row.cName === true) ? classes.headerImages : classes.deselectedHeaderImages}
                                         alt={row.title} key={row.name + "img"} style={{color:'red'}}/>
                                ))}
                            </div>}
                        </div>


                    </AccordionSummary>
                    <AccordionDetails>
                        {/* Accordion Data Pre-Funeral */}
                        <Prefuneral prefuneral={props.prefuneral} handleChecked={props.handleCheckedPre}
                                    handleTotal={props.handleTotalPre} rows={props.pref}/>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item md={12} xs={12} className="gridItemStyle">
                {/*Funeral accordion*/}
                <Accordion  expanded={expanded === 'panel3'}
                           onChange={handleChange('panel3')}
                           className={"secondAccordionOutline"}>
                    <AccordionSummary
                        name='panel3'
                        expandIcon={<ExpandMoreIcon className={"MuiSvgIcon-root1"}/>}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"

                        className="secondAccordionSummary">
                        <div style={{
                            display: 'flex',
                            flexFlow: 'row wrap',
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '40px',
                            fontSize:'26px',
                        }}>
                            {/*Heading*/}
                            <div className={classes.headerContainer}>
                                {(id=='met') ? (
                                <Typography className={"accordHeading"}>Funeral</Typography>):(
                                    <Typography className={"accordHeading"}>FUNERAL</Typography>
                                )}
                            </div>
                            {/*Total*/}
                            <div className={"accordHeading"}>
                                <Typography  className={"accordHeading"}>R{formatNumber(props.funeral.total)}</Typography>
                            </div>
                            {/*Images*/}
                            {(expanded !== 'panel3') && <div  style={{flexBasis: '100%',zIndex:'-1'}}>
                                {props.fune.map((row) => (
                                    <img id={11} src={row.icon} title='1' description='1'
                                         className={(row.cName === true) ? classes.headerImages : classes.deselectedHeaderImages}
                                         alt={row.title} key={row.name + "img"}/>
                                ))}
                            </div>}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails >
                        {/* Accordion Data Funeral */}
                        <Funeral funeral={props.funeral} handleChecked={props.handleCheckedFune}
                                 handleTotal={props.handleTotalFune} rows={props.fune}/>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item md={12} xs={12} className="gridItemStyle">
                {/*Post-Funeral accordion*/}
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
                           id={classes.postFuneralAccordian}
                           className={"thirdAccordionOutline"}>
                    <AccordionSummary
                        name='panel4'
                        expandIcon={<ExpandMoreIcon className={"MuiSvgIcon-root1"}/>}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"

                        className="thirdAccordionSummary">
                        {/* Accordion Header */}
                        <div
                            style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                                justifyContent: 'space-between',
                                width: '100%',
                                height: '40px',
                                fontSize:'26px',
                            }}>
                            {/*Heading*/}
                            <div className={classes.headerContainer}>
                                {(id=='met') ? (
                                <Typography className={"accordHeading"}>Post-funeral</Typography>):(
                                    <Typography className={"accordHeading"}>POST-FUNERAL</Typography>
                                    )}
                            </div>
                            {/*Total*/}


                            <div className={"accordHeading"}>
                                <Typography
                                    className={"accordHeading"}>R{formatNumber(props.postfuneral.total)}</Typography>
                            </div>
                            {/*Images*/}
                            {(expanded !== 'panel4') && <div style={{flexBasis: '100%',zIndex:'-1'}}>
                                {props.postf.map((row) => (
                                    <img id={11} src={row.icon} title='1' description='1'
                                         className={(row.cName === true) ? classes.headerImages : classes.deselectedHeaderImages}
                                         alt={row.title} key={row.name + "img"}/>
                                ))}
                            </div>}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails >
                        {/* Accordion Data Post-Funeral */}
                        <Postfuneral postfuneral={props.postfuneral} handleChecked={props.handleCheckedPost}
                                     handleTotal={props.handleTotalPost} rows={props.postf}/>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}