import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContext } from "react";
import { useEffect, useState } from "react";
import InfoIcon from '@material-ui/icons/Info';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import circle from "../../../../assets/images/circ.svg";
import arrowDownloadPlan from "../../../../assets/images/arrow_download_plan.svg";

const useStyles = makeStyles(() => ({
HowToFrame: {
    display: 'flex',
    flexWrap: 'Wrap',
    flexDirection: 'row',
    justifyContent: 'left',
    height: 'auto',
    width: '100%',
    borderRadius: 33,
    boxSizing: 'border-box',
    boxShadow: '-1px -1px 4px 4px rgba(0, 0, 0, 0.1)',
},
HowToFrame_mobile: {
    display: 'flex',
    flexWrap: 'Wrap',
    flexDirection: 'row',
    justifyContent: 'left',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: 20,
},
header: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'left',
    alignContent: 'center',
    flexBasis: '100%',
    backgroundColor: '#4353ff',
    height: '126px',
    width: '100%',
    paddingLeft: '10%',
    paddingRight: '10%',

},
closeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexBasis: '100%',
    position: 'absolute',
    width: 30,
    top: '3%',
    right: '5%',
},
heading: {
    flexBasis: '100%',
     marginBottom: 0,
    fontFamily: 'Raleway-Regular, Raleway, sans-serif',
    color: 'white',
},
subHeading: {
    fontSize: '14',
    fontFamily: 'Raleway-Regular, Raleway, sans-serif',
    color: 'white',
},
howTo_Body: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'left',
    alignContent: 'space-between',
    height: '80%',
    width: '100%',
    background: 'white',
    paddingTop: 20,
    borderRadius: '0px 0px 10px 10px', /*TL TR BR BL*/
},
body_text: {
    flexBasis: '100%',
    paddingLeft: '12%',
    paddingRight: '12%',
    color: 'rgba(45, 45, 45, 0.9)',
},
body_text_mobile: {
    flexBasis: '100%',
    paddingLeft: '5%',
    paddingRight: '5%',
    color: 'rgba(45, 45, 45, 0.9)',
},
body_subheading: {
    flexBasis: '100%',
     paddingLeft: '10%',
    paddingRight: '10%',
},
body_subheading_mobile: {
    flexBasis: '100%',
    paddingLeft: '3%',
    paddingRight: '3%',
    fontSize: '12px',
},
funeralPlannerButton: {
    fontSize: '12px',
    width: '154px',
    height: '36px',
    color: 'white',
    backgroundColor: '#222222',
    borderRadius: 5,
    boxSizing: 'border-box',
    fontFamily: 'Raleway-Regular, Raleway, sans-serif',
    cursor: 'pointer',
    marginLeft: '10%',
    marginBottom: 20,
},
images: {
    flexBasis: '100%',
    marginTop: 20,
    marginLeft: '15%',
},
images_mobile: {
    flexBasis: '100%',
    marginTop: 20,
    marginLeft: '2%',
},

}));

const HowToMet = (props) => {

    const styles = useStyles();

    /*Re-using from Breakdown*/
    const [isDesktop, setDesktop] = useState(window.innerWidth > 599);

    const updateMedia = () => {
      setDesktop(window.innerWidth > 599);
    };

    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return (
    <section>
        <div className={ isDesktop ? (styles.HowToFrame) : (styles.HowToFrame_mobile) }>
            <div className={styles.header} style={isDesktop? {} : {paddingLeft: '3%',paddingRight: '3%' ,borderRadius: '0'} }>
                <div className={styles.closeContainer} style={isDesktop? {} : {right: '1%', top: '1%'} }>
                    <img src={require('../../../images/closeIcon.svg')} alt='' style={{width: '30px',height: '30px',cursor: 'pointer'}} onClick={props.handleClose}/>
                </div>
                <Typography className={styles.heading}>HOW TO GUIDE - IN 5 STEPS</Typography>
                <Typography className={styles.subHeading}>A funeral event is made up of 3 stages: Pre-funeral, the funeral itself and post-funeral.</Typography>
            </div>
            <div className={styles.howTo_Body}>
                <div style={{flexBasis: '100%'}}>
                    <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) }>1. Select a section</Typography>
                    <Typography className={ isDesktop ? (styles.body_text) : (styles.body_text_mobile) }>(e.g Pre-Funeral)</Typography>
                </div>
                <div style={{flexBasis: '100%'}}>
                    <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) }>2. Choose the items you’d like to add and give it a value.</Typography>
                    <Typography className={ isDesktop ? (styles.body_text) : (styles.body_text_mobile) }>Unsure of anything? Click the
                    {<InfoIcon color="primary" style={{position: 'relative', top: '5px', marginLeft: '3px'}}/>} to get more information.</Typography>
                    <img src={require('../../../images/prefuneralAccordionmet.png')} alt='' className={ isDesktop ? (styles.images) : (styles.images_mobile) } style={{width: '282px'}}/>
                </div>
                <div style={{flexBasis: '100%'}}>
                    <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) }>3. The graph</Typography>
                    <Typography className={ isDesktop ? (styles.body_text) : (styles.body_text_mobile) }>Is an overview of where your money is going and the breakdown of everything you see.</Typography>
                    <img src={require('../../../images/DonutChartmet.png')} alt='' className={ isDesktop ? (styles.images) : (styles.images_mobile) } style={{width: '200px'}}/>
                </div>

                <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) } style={{marginBottom: '8px'}}>4. Once you are happy, generate a PDF to save all your hard work</Typography>

                <div style={{flexBasis: '100%'}}>
                    <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) }>5. Now that you have an idea of how much it will cost you…</Typography>
                    <Typography className={ isDesktop ? (styles.body_text) : (styles.body_text_mobile) }>Head over to the <a
                    href="https://www.metropolitaninsurance.co.za/" style={{color: '#4353ff', cursor: 'pointer'}} target={"_blank"}>Funeral page
                    </a> and buy it!</Typography>
                </div>
                <hr className={"mainDivider"}/>

                {/*Back to funeral planner section*/}
                <Container >
                    <div className={"buttonRibbon"}>
                        <Grid container justify="space-between" direction="row">
                            <Grid item xs={6} sm={6}>
                                <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) } style={{paddingBottom: '15px'}}>That's it! Ready?</Typography>
                            </Grid>


                        </Grid>
                    </div>
                </Container>
                {/*<div style={{flexBasis: '100%'}}>
                    <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) } style={{paddingBottom: '15px'}}>That's it! Ready?</Typography>
                    <Typography className={ isDesktop ? (styles.body_subheading) : (styles.body_subheading_mobile) } style={{paddingBottom: '05px', fontSize: 13}}>Ready?</Typography>
                    <button className={styles.funeralPlannerButton} style={isDesktop? {} : {marginLeft: '3%'}} onClick={props.handleClose}>FUNERAL PLANNER</button>
                    <button
                        type="button"
                        className="productButton forwardButton fullWidth funeralSelfServiceButton"
                        onClick={props.handleClose} >{"LET'S GO!"}
                    </button>
                </div>*/}
            </div>
        </div>
    </section>
    )
}

export default HowToMet