import React from 'react';
import axios from 'axios';
import {useHistory} from "react-router-dom";
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from "react";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from "@material-ui/core/Typography";
import circle from "../../../assets/images/circ.svg";
import arrowDownloadPlan from "../../../assets/images/arrow_download_plan.svg";

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: '0px',
        marginBottom: '5px',
    },

    container: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16

    },
    offsetButtonPrimary: {
        fontWeight: '600',
        textTransform: 'uppercase',
        color: 'rgba(67, 83, 255,1)',
        textAlign: 'center',
        lineHeight: 'normal',
        width: '200px',
        height: '51px',
        border: '1px solid rgba(67, 83, 255,1)',
        backgroundColor: 'white',
        borderRadius: '360px',
        "&:hover": {
            color: 'white',
            backgroundColor: 'rgba(67, 83, 255,1)',
            borderRadius: '360px',
            boxShadow: '3px 3px 0px 0px rgba(67, 83, 255,1),3px 3px 0px 0px white inset',
        },
        "&:focus": {
            color: 'white',
            backgroundColor: ' rgba(67, 83, 255,1)',
            borderRadius: '360px',
            boxShadow: '3px 3px 0px 0px rgba(67, 83, 255,1),3px 3px 0px 0px white inset',
            outline: 0,
        },
    },
    mobileButton_Quote: {
        fontWeight: '600',
        textTransform: 'uppercase',
        color: 'white',
        textAlign: 'center',
        lineHeight: 'normal',
        width: '200px',
        height: '51px',
        border: '1px solid white',
        backgroundColor: 'transparent',
        borderRadius: '360px',
    },
    mobileButton_Pdf: {
        fontWeight: '600',
        textTransform: 'uppercase',
        color: 'rgba(67, 83, 255,1)',
        textAlign: 'center',
        lineHeight: 'normal',
        width: '200px',
        height: '51px',
        border: '1px solid rgba(67, 83, 255,1)',
        backgroundColor: 'white',
        borderRadius: '360px',
    }
});

let API_URL = "";

const host = window && window.location && window.location.hostname;
if (host === "localhost") {
    API_URL = "https://api-dev.getup.metropolitan.co.za";
} else if (host === "develop.d110dgi2spjubq.amplifyapp.com" || host === "www.funeralplanner-dev.metgetup.co.za" || host==="funeral-calculater-met-redesign.d110dgi2spjubq.amplifyapp.com") {
    API_URL = "https://api-dev.getup.metropolitan.co.za";
} else if (host === "www.funeralplannermetgetup.co.za") {
    API_URL = "https://api.getup.metropolitan.co.za";
} else {
    API_URL = "https://api.getup.metropolitan.co.za";
}
{/*console.log("Host: " + host + "\nAPI_URL: " + API_URL);*/
}



const BreakdownButtons = (props) => {


    const [id, setId] = useState("");


    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setId(params.get('id'));
   });



   // const history = useHistory();
    const styles = useStyles();
    const routeToFun = () => {

        if((host === 'www.funeralplannermetgetup.co.za') || (host === 'www.funeralplanner.metgetup.co.za')) {
            window.open("https://www.metropolitaninsurance.co.za/?utm_id=100009&utm_source=direct&utm_medium=&utm_content=MFPFuneralPlanner&utm_source_platform=web&utm_creative_format=button&utm_marketing_tactic=lead_generation", "_blank");
        }
        else
        {
            window.open("https://develop.d3m9egys5fvkyp.amplifyapp.com/?utm_id=100009&utm_source=direct&utm_medium=&utm_content=MFPFuneralPlanner&utm_source_platform=web&utm_creative_format=button&utm_marketing_tactic=lead_generation", "_blank");
        }
    }

    const routeToFusion = () => {
        if((host === 'www.funeralplannermetgetup.co.za') || (host === 'www.funeralplanner.metgetup.co.za') ) {
            window.open("https://www.funeralcovermetgetup.co.za/?type=fusion", "_blank");
        }
        else
        {
            window.open("https://www.funeralcover-dev.metgetup.co.za/?type=fusion", "_blank");
        }
    }
    /*Button onClick function to generate pdf using pdfBuilder*/

    let funeralApi;
   if(id==='met'){
       funeralApi = '/pdfbuilder/documents/funeralcalculatormet/stream'
   }else{
       funeralApi = '/pdfbuilder/documents/funeralcalculator/stream'
   }


    const post = async () => {

        let breakdown = {
            Repatriation: props.prefuneral.repatCheck ? parseInt(props.prefuneral.repat) : 0,
            Undertaker: props.prefuneral.undertakerCheck ? parseInt(props.prefuneral.undertaker) : 0,
            Cremation: props.prefuneral.cremationCheck ? parseInt(props.prefuneral.cremation) : 0,
            Cemetery: props.prefuneral.cemeteryCheck ? parseInt(props.prefuneral.cemetery) : 0,
            Coffin: props.prefuneral.coffinCheck ? parseInt(props.prefuneral.coffin) : 0,
            Urn: props.prefuneral.urnCheck ? parseInt(props.prefuneral.urn) : 0,
            Arrangements: props.prefuneral.arrangementsCheck ? parseInt(props.prefuneral.arrangements) : 0,
            Livestock: props.prefuneral.livestockCheck ? parseInt(props.prefuneral.livestock) : 0,
            Clothes: props.prefuneral.clothesCheck ? parseInt(props.prefuneral.clothes) : 0,
            ServiceVenue: props.funeral.serviceVenueCheck ? parseInt(props.funeral.serviceVenue) : 0,
            Chairs: props.funeral.chairsCheck ? parseInt(props.funeral.chairs) : 0,
            Catering: props.funeral.cateringCheck ? parseInt(props.funeral.catering) : 0,
            FuneralService: props.funeral.funeServiceCheck ? parseInt(props.funeral.funeService) : 0,
            Flowers: props.funeral.flowersCheck ? parseInt(props.funeral.flowers) : 0,
            Programmes: props.funeral.programmesCheck ? parseInt(props.funeral.programmes) : 0,
            Music: props.funeral.musicCheck ? parseInt(props.funeral.music) : 0,
            Tombstone: props.postfuneral.tombCheck ? parseInt(props.postfuneral.tomb) : 0,
            UnveilCeremony: props.postfuneral.unveilCheck ? parseInt(props.postfuneral.unveil) : 0,
            CleansingCeremony: props.postfuneral.cleansCheck ? parseInt(props.postfuneral.cleans) : 0
        }
        console.log(breakdown)

        axios({
            method: 'POST',
            url: API_URL + funeralApi,
            responseType: 'blob',
            data: breakdown,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res);
            const file = new Blob([res.data], {type: "application/pdf"});
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL, "_blank");
        })
            .catch((error) => {
                console.log(error);
            });
    }
    /*Copied from breakdown*/
    const [isDesktop, setDesktop] = useState(window.innerWidth > 599);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 599);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <div>
        <div className={"breakdownContainer"}>
            <br/>
            <div className={styles.buttonContainer}>
                {/* <Button type="button" className={ isDesktop? styles.offsetButtonPrimary : styles.mobileButton_Pdf } variant="contained" onClick={post} startIcon={<CloudDownloadIcon />}>Download your plan</Button>
          <Button type="button" className={ isDesktop? styles.offsetButtonPrimary : styles.mobileButton_Quote } variant="contained" href="https://www.funeralcovermetgetup.co.za/">Get a quote</Button>
          <Button type="button" className={ isDesktop? styles.offsetButtonPrimary : styles.mobileButton_Quote } variant="contained" href="https://www.funeralcovermetgetup.co.za/?type=fusion">Get a fusion quote</Button>*/}
                {(id=='met') ? (
               <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained productButton closeDisclaimerButton"
                    tabIndex="0" type="button"><span className="MuiButton-label-close" onClick={post}>&nbsp; Download your plan<span
                ><img src={circle} className={"circleArrow2"} />
                                </span></span><span className="MuiTouchRipple-root MuiButton-iconSizeMedium"></span></button>

               ):(

                <button
                    type="button"
                    className="productButton forwardButton fullWidth funeralSelfServiceButton"
                    onClick={post}>{" DOWNLOAD YOUR PLAN"}
                </button>)}
                <br/><br/><br/>
            </div>
            {(id=='met') ? (<img src={arrowDownloadPlan} className={"arrowDownloadPlanB"}/>):(
                <img src={arrowDownloadPlan} className={"arrowDownloadPlanB"} onClick={post}/>
            )}
            {(id!='met')?(
            <div className={"disclaimerBlock"}>
                <div className={"disclaimerBlockContent"}>
                    <Typography variant={"h4"} component={"h4"} className={"disclaimerBlockHeader"}>
                        NEED COVER?
                    </Typography>
                    <p className="disclaimerContent">You could pay up to 40% less on your funeral premiums with Met
                        GetUp. Get a quote and see for yourself. </p>
                    <button

                        type="button"

                        className={`productButton blueOutlineButton fullWidth funeralSelfServiceButton`}
                        onClick={routeToFun}>{"Get a quote"}
                    </button>
                </div>
            </div>):("")}

      {/*      <div className={"disclaimerBlockLast"} >
                <div className={"disclaimerBlockContent"}>
                    <Typography variant={"h4"} component={"h4"} className={"disclaimerBlockHeader"}>
                        PAYING TOO MUCH?
                    </Typography>
                    <p className="disclaimerContent">If you have more than one funeral policy, we can combine all your
                        cover, into a single policy at up to 30% less.</p>
                    <button

                        type="button"

                        className={`productButton blueOutlineButton fullWidth funeralSelfServiceButton`}
                        onClick={routeToFusion}>{"Get a fusion quote"}
                    </button>
                </div>
            </div>*/}
        </div>

        </div>
    )
}

export default BreakdownButtons