import React, {Component} from "react"

import PropTypes from "prop-types"
/*import logo from '../../assets/images/GetUp_Logo.svg';*/
import logo from '../../../assets/images/LogoMain@2x.png';
import closeIcon from '../../../assets/images/closeIcon.svg'
import {API_CONFIG} from "../../../config/api-config";
import {
    AppBar,
    Toolbar,
    Typography,
    List,
    ListItem,
    withStyles,
    Grid,
    SwipeableDrawer,
    Link,
    Container,
    CssBaseline, Paper
} from "@material-ui/core"

import MenuIcon from "@material-ui/icons/Menu"

const styleSheet = {
    list: {
        width: 200,
    },
    padding: {
        paddingRight: 10,
        cursor: "pointer",
        color: '#000000',
        fontFamily: 'Hind Siliguri',
        fontSize: '14px',
        textDecoration: 'none solid rgb(0, 0, 0)',
        letterSpacing: '0px',
        textTransform: 'uppercase',
        textAlign: 'right',
        paddingTop: '25px',
        fontWeight: '500',


    },
    paddingMobi: {
        paddingRight: 10,
        cursor: "pointer",
        color: "#fff",
        textDecoration: 'none',
        '& a': {
            textDecoration: 'none',
        },
        '& i': {
            textDecoration: 'none',
            color: '#fff'
        }
    },

    sideBarIcon: {
        padding: 0,
        color: "#000",
        cursor: "pointer",
    },
    link: {
        padding: 0,
        color: "white",
        cursor: "pointer",
    },
    mobiLinkIterm: {
        height: '52px',
        width: '235px',
        fill: '#282828',
        borderTopWidth: '1px',
        borderTopColor: 'rgb(30, 30, 30)',
        borderTopStyle: 'solid',
    },
    mobiLinkPrdct: {
        height: '52px',
        width: '235px',
        fill: '#282828',
        borderTopWidth: '1px',
        borderTopColor: 'rgb(30, 30, 30)',
        borderTopStyle: 'solid',
    }
}

class Submenu extends React.Component {
    render() {
        return (
            <Grid container spacing={2} className="nav__submenu">
                <Grid item xs={6} style={{width: '50%'}}>
                    <ul >
                        <li className="nav__submenu-item products main desktop">
                            <Link href={API_CONFIG.SHOPFRONT_URL.concat("products")} target="_blank"
                                  rel="noopener noreferrer">
                                Products
                            </Link>
                        </li>
                        <li className="nav__submenu-item products sub desktop">
                            <Link href={API_CONFIG.SHOPFRONT_URL.concat("product/getup-atom/")} target="_blank"
                                  rel="noopener noreferrer">
                                GetUp Funeral Plan
                            </Link>
                        </li>
                        <li className="nav__submenu-item products sub desktop">
                            <Link href={API_CONFIG.SHOPFRONT_URL.concat("product/funeral-cover")} target="_blank"
                                  rel="noopener noreferrer">
                                Metropolitan Funeral Plan
                            </Link>
                        </li>
                       {/* <li className="nav__submenu-item products sub desktop">
                            <Link href={API_CONFIG.SHOPFRONT_URL.concat("product/funeral-fusion/")} target="_blank"
                                  rel="noopener noreferrer">
                                Funeral fusion
                            </Link>
                        </li>*/}


                    </ul>
                </Grid>
                <Grid item xs={6} style={{width: '50%'}} >
                    <ul >
                        <li className="nav__submenu-item lifehacks main desktop">
                            <Link href={API_CONFIG.SHOPFRONT_URL.concat("#")} target="_blank" rel="noopener noreferrer">
                                Life Hacks
                            </Link>
                        </li>
                        <li className="nav__submenu-item lifehacks sub desktop">
                            <Link href={API_CONFIG.SHOPFRONT_URL.concat("lifehacks/getupwill/")} target="_blank" rel="noopener noreferrer">
                                GetUp Will
                            </Link>
                        </li>
                        <li className="nav__submenu-item lifehacks sub desktop">
                            <Link href={API_CONFIG.FUNERALPLANNER_URL} target="_blank" rel="noopener noreferrer">
                                Funeral planner
                            </Link>
                        </li>
                        {/* <li className="nav__submenu-item lifehacks sub desktop">
                            <Link href={API_CONFIG.SHOPFRONT_URL.concat("lifehacks/free-credit-report/")} target="_blank" rel="noopener noreferrer">
                                Free credit report
                            </Link>
                        </li> */}
                    </ul>
                </Grid>
            </Grid>

        )
    }
}

class SubmenuMobile extends React.Component {
    render() {
        return (

            <ul className="nav__submenu navbar fixed-top navbar-expand-lg scrolling-navbar navbar-light white">


                <li className="nav__submenu-item products sub mobile hver product_list_item">
                    <a href={API_CONFIG.SHOPFRONT_URL.concat("product/getup-atom/")} target="_blank"
                       rel="noopener noreferrer">GetUp Funeral Plan
                    </a>
                </li>
                <li className="nav__submenu-item products sub mobile hver product_list_item">
                    <a href={API_CONFIG.SHOPFRONT_URL.concat("product/funeral-cover")} target="_blank"
                       rel="noopener noreferrer">Metropolitan Funeral Plan
                    </a>
                </li>
                {/*<li className="nav__submenu-item products sub mobile hver product_list_item">
                    <a href={API_CONFIG.SHOPFRONT_URL.concat("product/funeral-fusion")} target="_blank"
                       rel="noopener noreferrer">Funeral fusion
                    </a>
                </li>*/}
                <li className="nav__submenu-item products sub mobile hver product_show_all_list_item">
                    <a href={API_CONFIG.SHOPFRONT_URL.concat("products")} target={"_blank"} rel="noopener noreferrer">Browse all products
                    </a>
                </li>
            </ul>

        )
    }
}



class SubmenuLifeHacksMobile extends React.Component {
    render() {
        return (

            <ul className="nav__submenu navbar fixed-top navbar-expand-lg scrolling-navbar navbar-light white">

                <li className="nav__submenu-item lifehacks sub mobile hver lifeHacks_list_item">
                    <Link href={API_CONFIG.SHOPFRONT_URL.concat("lifehacks/getupwill/")} target="_blank"
                          rel="noopener noreferrer">
                        GetUp Will
                    </Link>
                </li>
                <li className="nav__submenu-item lifehacks sub mobile hver lifeHacks_list_item">
                    <Link href={API_CONFIG.FUNERALPLANNER_URL} target="_blank" rel="noopener noreferrer">
                        Funeral planner
                    </Link>
                </li>
                {/* <li className="nav__submenu-item lifehacks sub mobile hver lifeHacks_list_item">
                    <Link href={API_CONFIG.SHOPFRONT_URL.concat("lifehacks/free-credit-report/")} target="_blank"
                          rel="noopener noreferrer">
                        Free credit report
                    </Link>
                </li> */}
                {/* <li className="nav__submenu-item lifehacks sub mobile hver lifeHacks_show_all_list_item">
                    <a href={API_CONFIG.SHOPFRONT_URL.concat("lifehacks")} target="_blank" rel="noopener noreferrer">
                        Browse all life hacks
                    </a>
                </li> */}
            </ul>

        )
    }
}


class Navigation extends Component {


    constructor(props) {
        super(props)
        this.state = {
            drawerActivate: false,
            drawer: false,
            right: false,
            showAboutMenu: false,
            navClass: 'navLeave',
            navLHClass: 'navLeave',
            showMenu: false,
            showLifeHacksMenu: false
        }
        this.createDrawer = this.createDrawer.bind(this)
        this.destroyDrawer = this.destroyDrawer.bind(this)


        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.showLifeHacksMenu = this.showLifeHacksMenu.bind(this);
        this.closeLifeHacksMenu = this.closeLifeHacksMenu.bind(this);


    }

    showMenu(event) {
        event.preventDefault();
        //console.log("Click open")
        this.setState({showMenu: true}, () => {
            document.addEventListener('click', this.closeMenu);
            //console.log(JSON.stringify(this.state))
        });
    }

    closeMenu() {
        //console.log("Click close")
        this.setState({showMenu: false}, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    showLifeHacksMenu(event) {
        event.preventDefault();
        //console.log("Click open")
        this.setState({showLifeHacksMenu: true}, () => {
            document.addEventListener('click', this.closeLifeHacksMenu);
            //console.log(JSON.stringify(this.state))
        });
    }

    closeLifeHacksMenu() {
        //console.log("Click close")
        this.setState({showLifeHacksMenu: false}, () => {
            document.removeEventListener('click', this.closeLifeHacksMenu);
        });
    }

    closeBtn = () => {
        this.setState({drawer: false})
    }
    handleHover = () => {

        this.setState({showAboutMenu: true});

        var navClass = 'navEnter';
        this.setState({navClass});

    };

    handleLeave = () => {
        var navClass = 'navLeave';

        this.setState({navClass});
        // this.setState({ showAboutMenu: false });
    };

    handleLHHover = () => {

        this.setState({showAboutMenu: true});

        var navLHClass = 'navEnter';
        this.setState({navLHClass});

    };

    handleLHLeave = () => {
        var navLHClass = 'navLeave';

        this.setState({navLHClass});
        // this.setState({ showAboutMenu: false });
    };


    UNSAFE_componentWillMount() {
        if (typeof window !== "undefined" && window.innerWidth <= 1024) {
            this.setState({drawerActivate: true})
        }
        if (typeof window !== "undefined") {
            window.addEventListener("resize", () => {
                if (window.innerWidth <= 1024) {
                    this.setState({drawerActivate: true})
                } else {
                    this.setState({drawerActivate: false})
                }
            })
        }
        if (typeof window !== "undefined") {
            var activeClass = 'topClass';
            this.setState({activeClass});
            window.addEventListener('scroll', () => {
                activeClass = 'normalClass';

                if (window.scrollY === 0) {
                    activeClass = 'topClass';
                }
                this.setState({activeClass});
            });
        }
    }

    //Small Screens
    createDrawer() {
        const {classes, siteTitle} = this.props
        return (
            <div>
                <AppBar style={{backgroundColor: '#1e1e1e'}} className={`${this.state.activeClass}`}>
                    <Toolbar>
                        <Grid item xs={6}>
                            <Typography style={{flexGrow: 1, paddingTop: 14, paddingLeft: 14}} className="logo">
                                <Link href={API_CONFIG.SHOPFRONT_URL} target="_blank" rel="noopener noreferrer"
                                      className={classes.padding} aria-label={"GetUp Shopfront"}>
                                    <img src={logo} alt={siteTitle} width="auto" height="40"/>
                                </Link>
                            </Typography>
                            <Typography></Typography>
                        </Grid>
                        <Grid item xs={6}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center"

                        >
                            <MenuIcon
                                className={this.props.classes.sideBarIcon}
                                onClick={() => {
                                    this.setState({drawer: true})

                                    this.setState({showAboutMenu: true})

                                }}
                            />

                        </Grid>

                    </Toolbar>
                </AppBar>

                <SwipeableDrawer
                    id={"navHamburgerMenu"}
                    anchor="right"
                    open={this.state.drawer}
                    onClose={() => {
                        this.setState({drawer: false})
                    }}
                    onOpen={() => {
                        this.setState({drawer: true, showAboutMenu: true})

                    }}

                >
                    <div className="closeBtn"><span onClick={() => {
                        this.setState({drawer: false})
                    }}><img src={closeIcon} alt={siteTitle} width="24" height="24"/></span></div>
                    <div
                        tabIndex={0}
                        role="button"
                        className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"
                        /*onClick={() => {this.setState({ drawer: false })}}*/

                        onKeyDown={() => {
                            this.setState({drawer: false})
                        }}
                        style={{backgroundColor: '#1e1e1e'}}

                    >

                        <List className={this.props.classes.list}>
                            <ListItem button divider className={classes.mobiLinkIterm}
                                      style={{borderTop: '1px solid #282828', fill: '#282828'}}>
                                <a href={API_CONFIG.SHOPFRONT_URL} target={"_blank"} rel="noopener noreferrer"
                                   className={classes.paddingMobi} onClick={this.closeBtn}>
                                    Home
                                </a>
                            </ListItem>
                            <ListItem button divider className={classes.mobiLinkIterm}
                                      style={{borderTop: '1px solid #282828', fill: '#282828'}}>
                                <a href={API_CONFIG.SHOPFRONT_URL.concat("aboutus")} target={"_blank"} rel="noopener noreferrer"
                                   className={classes.paddingMobi} onClick={this.closeBtn}>
                                    About
                                </a>
                            </ListItem>
                            <ListItem button divider className={classes.mobiLinkIterm}
                                      style={{borderTop: '1px solid #282828', fill: '#282828'}} onClick={this.showMenu}>
                                <a  target={"_blank"}
                                   rel="noopener noreferrer" className={classes.paddingMobi} style={{float: 'right'}}>
                                    Products
                                </a>
                                <div style={{float: 'left'}}><i className="arrow down"></i></div>


                            </ListItem>
                            <div>

                                {
                                    this.state.showMenu
                                        ? (

                                                <div id="i-want-this-to-collapse" className="collapse navbar-collapse">
                                                    <nav className="nav  navbar-nav navbar-right visible-xs-block">

                                                        <ListItem button divider
                                                                  className={`nav__menu-item ${classes.mobiLinkPrdct}`}>

                                                            <SubmenuMobile/>

                                                        </ListItem>

                                                    </nav>
                                                </div>


                                        )
                                        : null

                                }
                            </div>
                            <ListItem button divider className={classes.mobiLinkIterm}
                                      style={{borderTop: '1px solid #282828', fill: '#282828'}} onClick={this.showLifeHacksMenu}>
                                <a href={API_CONFIG.SHOPFRONT_URL.concat("#")} target={"_blank"} rel="noopener noreferrer" className={classes.paddingMobi} style={{float: 'right'}}>
                                    Life Hacks
                                </a>
                                <div style={{float: 'left'}}><i className="arrow down"></i></div>


                            </ListItem>
                            <div>

                                {
                                    this.state.showLifeHacksMenu
                                        ? (

                                            <div id="i-want-this-to-collapse" className="collapse navbar-collapse">
                                                <nav className="nav  navbar-nav navbar-right visible-xs-block">

                                                    <ListItem button divider
                                                              className={`nav__menu-item ${classes.mobiLinkPrdct}`}>

                                                        <SubmenuLifeHacksMobile/>

                                                    </ListItem>

                                                </nav>
                                            </div>

                                        )
                                        : null
                                }
                            </div>

                            <ListItem button divider className={classes.mobiLinkIterm}>

                                <a href={API_CONFIG.SHOPFRONT_URL.concat("blog")} target="_blank"
                                   rel="noopener noreferrer" className={classes.paddingMobi} onClick={this.closeBtn}>
                                    Blog
                                </a>
                            </ListItem>
                            <ListItem button divider className={classes.mobiLinkIterm}>

                                <a href={API_CONFIG.SHOPFRONT_URL.concat("products/claims")} target="_blank"
                                   rel="noopener noreferrer" className={classes.paddingMobi} onClick={this.closeBtn}>
                                    Claims
                                </a>
                            </ListItem>
                            <ListItem button divider className={classes.mobiLinkIterm}>

                                <a href="https://metrohelp.zendesk.com/hc/en-us" target="_blank"
                                   rel="noopener noreferrer" className={classes.paddingMobi}
                                   rel="noopener norefferer"> Get help </a>
                            </ListItem>
                            <ListItem button divider className={classes.mobiLinkIterm}>

                                <a href={API_CONFIG.PROFILE_URL.concat("lifehacks/overview?_ga=2.15953526.1703365867.1627844876-538504553.1611564044")}
                                   target="_blank" rel="noopener noreferrer" className={classes.paddingMobi}
                                > Login/Sign up </a>
                            </ListItem>
                        </List>
                    </div>
                </SwipeableDrawer>
            </div>
        )
    }

    //Larger Screens
    destroyDrawer() {
        const {classes, siteTitle} = this.props
        return (


            <React.Fragment>
                <CssBaseline/>

                <AppBar style={{backgroundColor: 'hsla(0,0%,100%,.5)'}} className={`${this.state.activeClass}`}>
                    <Toolbar>
                        <Container maxWidth="lg">
                            <Grid container className={classes.mainGrid}>
                                <Typography style={{flexGrow: 1, paddingTop: 14}} className="logo">
                                    <a href={API_CONFIG.SHOPFRONT_URL} aria-label={"GetUp Shopfront"} target="_blank"
                                       rel="noopener noreferrer" className={classes.padding}><img src={logo}
                                                                                                  alt={siteTitle}
                                                                                                  width="auto"
                                                                                                  height="40"/>
                                    </a>
                                </Typography>
                                <Typography className={classes.padding}>

                                    <a href={API_CONFIG.SHOPFRONT_URL} target="_blank" rel="noopener noreferrer"
                                       className={classes.padding}>
                                        Home
                                    </a>
                                </Typography>

                                <Typography className={classes.padding}>

                                    <a href={API_CONFIG.SHOPFRONT_URL.concat("aboutus")} target="_blank" rel="noopener noreferrer"
                                       className={classes.padding}>
                                        About
                                    </a>
                                </Typography>
                                <nav className="nav">
                                    <div className={`nav__menu-item desktop ${classes.padding} ${this.state.navClass}`}
                                         onMouseLeave={this.handleLeave}>
                                        <a onMouseEnter={this.handleHover}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={`prodHead ${classes.padding} ${this.state.navClass}`}>
                                            Offerings <i className={`arrow down ${this.state.navClass}`}></i></a>
                                        {this.state.showAboutMenu && <Submenu/>}
                                    </div>
                                </nav>
                                {/*<nav className="nav">
                                    <div className={`nav__menu-item ${classes.padding} ${this.state.navLHClass}`}
                                         onMouseLeave={this.handleLHLeave}>
                                        <a onMouseEnter={this.handleLHHover}
                                           href={API_CONFIG.SHOPFRONT_URL.concat("lifehacks")} target="_blank"
                                           rel="noopener noreferrer"
                                           className={`prodHead ${classes.padding} ${this.state.navLHClass}`}>
                                            Life Hacks <i className={`arrow down ${this.state.navLHClass}`}></i></a>
                                        {this.state.showAboutMenu && <SubmenuLifeHacks/>}
                                    </div>
                                </nav>*/}
                                {/*<Typography className={classes.padding}>
                                    <a href={"https://www.metgetup.co.za/lifehacks"} className={classes.padding}>
                                        Life Hacks
                                    </a>
                                </Typography>*/}
                                <Typography className={classes.padding}>
                                    <a href={API_CONFIG.SHOPFRONT_URL.concat("blog")} target="_blank"
                                       rel="noopener noreferrer" className={classes.padding}>
                                        Blog
                                    </a>
                                </Typography>

                                <Typography className={classes.padding}>
                                    <a href={API_CONFIG.SHOPFRONT_URL.concat("products/claims")} target="_blank"
                                       rel="noopener noreferrer" className={classes.padding}>
                                        Claims
                                    </a>
                                </Typography>
                                <Typography className={classes.padding}>
                                    <a href="https://metrohelp.zendesk.com/hc/en-us" target="_blank"
                                       rel="noopener noreferrer" className={classes.padding}
                                    > Get help </a>
                                </Typography>
                                <Typography className={classes.padding}>
                                    <a href={API_CONFIG.PROFILE_URL.concat("lifehacks/overview?_ga=2.15953526.1703365867.1627844876-538504553.1611564044")}
                                       className={classes.padding}
                                       target="_blank" rel="noopener noreferrer"> Login/Sign up </a>
                                </Typography>
                            </Grid>
                        </Container>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div>
                {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
            </div>
        )
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
    siteTitle: PropTypes.string,
    siteDesc: PropTypes.string,
}

Navigation.defaultProps = {
    siteTitle: ``,
    siteDesc: ``,
}

export default withStyles(styleSheet)(Navigation)
