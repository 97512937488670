import Typography from "@material-ui/core/Typography";

import React from "react";

function MetCopyright() {

    return (
        <React.Fragment>
            <Typography variant="body2" style={{textAlign: 'left', paddingTop: '35px'}}>
                <b><a href="https://www.metropolitan.co.za/legal/terms-privacy/"  style={{color:'#FFFFFF'}}>Terms of Use</a>   &nbsp;&nbsp;|&nbsp;&nbsp;  <a href="https://www.metropolitan.co.za/legal/terms-privacy/#privacy-policy"  style={{color:'#FFFFFF'}}>Privacy Policy</a></b> <br/><br/>
                Metropolitan Life is part of Momentum Metropolitan Life Limited, a licensed life insurer and <br/>an authorised financial service (FSP44673) and registered credit provider (NCRCP173).{/*new Date().getFullYear()*/}
                {/*<br/>
    Icons made by <a href="https://www.freepik.com" target="_blank" rel="noopener noreferrer">Freepik</a> from <a href="https://www.flaticon.com/" target="_blank" rel="noopener noreferrer">www.flaticon.com</a>*/} </Typography>
            {/*<button type="button" className={"invisibleButton"} onClick={noPlaceLikeHome}></button>*/}
        </React.Fragment>);
}

export default MetCopyright
