let API_URL;
let SHOPFRONT_URL;
let FUNERALPLANNER_URL;
let PROFILE_URL;
let MET_URL;
let HOME_URL;
let FUNERAL_PLANER;
let CONTACTUS_URL;
let CONTENTSHOPFRONT_URL


const host = window && window.location && window.location.hostname;


if(host === "localhost"){
     //API_URL = "https://localhost:9260";
     API_URL = "https://api-dev.getup.metropolitan.co.za";
     //API_URL = "https://api.getup.metropolitan.co.za";
     CONTENTSHOPFRONT_URL="https://content.metgetup.co.za"
     SHOPFRONT_URL = "https://develop1-0.d2omnxwob7neqd.amplifyapp.com/";
     PROFILE_URL = "https://www.mymetgetup-dev.co.za/";
     FUNERALPLANNER_URL = "https://www.metropolitan.co.za/funeral/";
     HOME_URL="https://www.metropolitan.co.za/"
     CONTACTUS_URL="https://www.metropolitan.co.za/contact-us/"


}
else if ((host === "www.funeralplanner.metgetup.co.za") || (host === "www.funeralplannermetgetup.co.za")){
     API_URL = "https://api.getup.metropolitan.co.za";
     SHOPFRONT_URL = "https://www.metgetup.co.za/"
     CONTENTSHOPFRONT_URL="https://content.metgetup.co.za"
     MET_URL = "https://www.metropolitan.co.za"
     PROFILE_URL = "https://www.mymetgetup.co.za/";
     FUNERALPLANNER_URL = "https://www.metropolitan.co.za/funeral/";
     HOME_URL="https://www.metropolitan.co.za/"
     CONTACTUS_URL="https://www.metropolitan.co.za/contact-us/"
}
else
{
     API_URL = "https://api-dev.getup.metropolitan.co.za";
     SHOPFRONT_URL = "https://develop1-0.d2omnxwob7neqd.amplifyapp.com/";
     PROFILE_URL = "https://www.mymetgetup-dev.co.za/";
     FUNERALPLANNER_URL = "https://www.metropolitan.co.za/funeral/";
     CONTENTSHOPFRONT_URL="https://content.metgetup.co.za"
     HOME_URL="https://www.metropolitan.co.za/"
     CONTACTUS_URL="https://www.metropolitan.co.za/contact-us/"
}

export const API_CONFIG = {API_URL: API_URL, SHOPFRONT_URL: SHOPFRONT_URL, FUNERALPLANNER_URL: FUNERALPLANNER_URL, PROFILE_URL: PROFILE_URL,HOME_URL:HOME_URL,CONTACTUS_URL:CONTACTUS_URL, CONTENTSHOPFRONT_URL:CONTENTSHOPFRONT_URL};
