import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import React from "react";

/*function Copyright() {
    return (
        <Typography variant="body2" color="textPrimary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Metropolitan GetUp
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}*/
function Copyright() {
    return (
        <Typography variant="body2" style={{fontSize: '10px', textAlign: 'center', paddingTop: '35px' }}>
            Metropolitan GetUp is part of Momentum Metropolitan Life Limited, an authorised financial service (FSP 44673) and registered credit provider (NCRCP173), and rated B-BBEE level 1
            {/*new Date().getFullYear()*/}
        </Typography>
    );
}

export default Copyright
