import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import  {Grid, Paper} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { FaTwitterSquare, FaFacebookSquare, FaLinkedin , FaInstagram, FaYoutubeSquare} from 'react-icons/fa';
 
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'none',
    width:'100%',
    margin: 0,
    justifyContent: 'left',
  },
  paper: {
    height: 32,
    width: 32,
    backgroundColor: 'RGBA(255, 255, 255, 0)',
    padding: 0,

    fontSize: '20px',
  },
  control: {
    padding: theme.spacing(1),
  },
  itemsList:{
    padding: '0px !important',
    backgroundColor: 'none',

  },
  socialLink:{
    color: '#fff !important',
    fontSize: '22px !important',
  }
  
}));


const SocialAccounts = () => {

  const [spacing] = React.useState(2);
  const classes = useStyles();

   
        return (<div>
                   
                    <Grid container className={classes.root} spacing={2}>
                      <Grid item xs={12}>
                        <Grid container justify="flex-start" spacing={spacing}>
                         
                           <Grid  item className={classes.itemsList}>
                              <Paper className={classes.paper}><Link className={classes.socialLink} href={`https://www.facebook.com/Met-GetUp-115609729885791/`} target="_blank"><FaFacebookSquare /></Link></Paper>
                            </Grid>
                            <Grid  item className={classes.itemsList}>
                              <Paper className={classes.paper}><Link className={classes.socialLink} href={`https://twitter.com/MetGetUp`} target="_blank"><FaTwitterSquare /></Link></Paper>
                            </Grid>
                            <Grid  item className={classes.itemsList}>
                              <Paper className={classes.paper}><Link className={classes.socialLink} href={`https://www.instagram.com/metgetup/`} target="_blank"><FaInstagram /></Link></Paper>
                            </Grid>
                            <Grid  item className={classes.itemsList}>
                              <Paper className={classes.paper}><Link className={classes.socialLink} href={`https://www.linkedin.com/company/met-getup/`}  target="_blank"><FaLinkedin /></Link></Paper>
                            </Grid>
                            <Grid  item className={classes.itemsList}>
                              <Paper className={classes.paper}><Link className={classes.socialLink} href={`https://www.youtube.com/channel/UCOkVVizuJWURT62T7gKXijw`} target="_blank"><FaYoutubeSquare /></Link></Paper>
                            </Grid>
                            
                        </Grid>
                      </Grid>
                      </Grid>
              </div>
        )
}

export default  SocialAccounts;