import React, { Component } from "react"
import { Link  } from 'react-router-dom'
import PropTypes from "prop-types"
import Container from '@material-ui/core/Container'
import logo from '../../images/GetUp_Logo.svg'
import closeIcon from '../../images/closeIcon.svg'
import MenuIcon from "@material-ui/icons/Menu"
import './navbar.css';
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  withStyles,
  Grid,
  SwipeableDrawer,
  LINK,
  CssBaseline
} from "@material-ui/core";

const styleSheet = {
  list: {
    width: 200,
  },
  padding: {
    paddingRight: 10,
    cursor: "pointer",
    color: '#000000',
    fontFamily: 'Hind Siliguri Medium',
    fontSize: '14px',
    textDecoration: 'none solid rgb(0, 0, 0)',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    textAlign: 'right',
    paddingTop: '25px',
    fontWeight: '600',
  },
  paddingMobi: {
    paddingRight: 10,
    cursor: "pointer",
    color: "#fff",
    textDecoration: 'none',
  '& a':{
    textDecoration: 'none',
  },
  '& i':{
    textDecoration: 'none',
    color: '#fff'
  }
},

  sideBarIcon: {
    padding: 0,
    color: "#000",
    cursor: "pointer",
  },
  link: {
    padding: 0,
    color: "white",
    cursor: "pointer",
  },
  mobiLinkIterm:{
    height:'52px',
    width: '235px',
    fill: '#282828',
    borderTopWidth: '1px',
    borderTopColor: 'rgb(30, 30, 30)',
    borderTopStyle: 'solid',
  },
  mobiLinkPrdct:{
    height:'52px',
    width: '235px',
    fill: '#282828',
    borderTopWidth: '1px',
    borderTopColor: 'rgb(30, 30, 30)',
    borderTopStyle: 'solid',
  }
}
class Submenu extends React.Component {
  render() {
    return (

        <ul className="nav__submenu">
        {/* <li  className="nav__submenu-item hver">
            <a href="https://www.metgetup.co.za/products/free-credit-report" activeClassName="active">
              Free credit report
            </a>
        </li> */}
        <li  className="nav__submenu-item hver">
        <a href="https://www.metgetup.co.za/products/debts-consolidation-loan" activeClassName="active">
              Debt consolidation loan
            </a>
        </li>
        <li  className="nav__submenu-item hver">
        <a href="https://www.metgetup.co.za/products/income-protection" activeClassName="active">
        Income Protection
            </a>
        </li>
        <li  className="nav__submenu-item hver">
        <a href="https://www.metgetup.co.za/products/funeral-cover" activeClassName="active">
              Funeral cover
            </a>
        </li>
        {/* <li className="nav__submenu-item hver">
            <a href="https://www.metgetup.co.za/lifehacks" activeClassName="active">
              Life Hacks
            </a>
        </li> */}

          </ul>

    )
  }
}

class SubmenuMobile extends React.Component {
  render() {
    return (
      
        <ul className="nav__submenu navbar fixed-top navbar-expand-lg scrolling-navbar navbar-light white">
        <li  className="nav__submenu-item hver">
        <a href="https://www.metgetup.co.za/products"  activeClassName="active">
              - Browse all products
            </a>
        </li>
        {/* <li  className="nav__submenu-item hver">
            <a href="https://www.metgetup.co.za/product/free-credit-report" activeClassName="active">
              - Free credit report
            </a>
        </li> */}
        <li  className="nav__submenu-item hver">
        <a href="https://www.metgetup.co.za/product/debts-consolidation-loan" activeClassName="active">
              - Debt consolidation loan
            </a>
        </li>
        <li  className="nav__submenu-item hver">
        <a href="https://www.metgetup.co.za/product/income-protection" activeClassName="active">
              - Income Protection
            </a>
        </li>
        <li  className="nav__submenu-item hver">
        <a href="https://www.metgetup.co.za/product/funeral-cover" activeClassName="active">
              - Funeral cover
            </a>
        </li>
        {/* <li className="nav__submenu-item hver">
            <a href="https://www.metgetup.co.za/lifehacks" activeClassName="active">
              - Life Hacks
            </a>
        </li> */}
       </ul>
    
    )
  }
}

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = { drawerActivate: false, drawer: false, right: false,showAboutMenu: false }
    this.createDrawer = this.createDrawer.bind(this)
    this.destroyDrawer = this.destroyDrawer.bind(this)
    this.state = {
      
    };
    this.state = {
      showMenu: false,
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  
  showMenu(event) {
    event.preventDefault();
    
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }
  
  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }
  closeBtn = () =>{
    this.setState({ drawer: false })
  }
  handleHover = () => {
    
    this.setState({ showAboutMenu: true });
    
    var navClass = 'navEnter';
    this.setState({ navClass });

  };

  handleLeave = () => {
    var navClass = 'navLeave';

    this.setState({ navClass });
     // this.setState({ showAboutMenu: false });
  };


  UNSAFE_componentWillMount() {
    if (typeof window !== "undefined" && window.innerWidth <= 766) {
      this.setState({ drawerActivate: true })
    }
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        if (window.innerWidth <= 766) {
          this.setState({ drawerActivate: true })
        } else {
          this.setState({ drawerActivate: false })
        }
      })
    }
    if (typeof window !== "undefined") {
      var activeClass = 'topClass';
      this.setState({ activeClass });
    window.addEventListener('scroll', () => {
      activeClass = 'normalClass';
     
      if(window.scrollY === 0){
          activeClass = 'topClass';
      }
      this.setState({ activeClass });
   });
  }
  }

  //Small Screens
  createDrawer() {
    const { classes, siteTitle } = this.props
    return (
      <div>
        <AppBar style={{ backgroundColor: '#1e1e1e'}} className={`${this.state.activeClass}`}>
          <Toolbar style={{backgroundColor: 'white'}}>
          <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
          <Grid item xs={6}>
          <Typography style={{ flexGrow: 1 }}  className="logo">
              <a href="https://www.metgetup.co.za" className={classes.padding} activeClassName="active">
              <img src={logo} alt={siteTitle} width="auto" height="59"  />
              </a>
          </Typography>
              <Typography></Typography>
        </Grid >
        </Grid>
            <Grid item xs={1}
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <MenuIcon
                className={this.props.classes.sideBarIcon}
                onClick={() => {
                  this.setState({ drawer: true })
                  
                  this.setState({ showAboutMenu: true })
                  
                }}
              />
              
        </Grid> 
        
          </Toolbar>
        </AppBar >

        <SwipeableDrawer
          anchor="right"
          open={this.state.drawer}
          onClose={() => {
            this.setState({ drawer: false })
          }}
          onOpen={() => {
            this.setState({ drawer: true , showAboutMenu: true})
         
          }}
          
        >
          <div className="closeBtn" ><span  onClick={() => {this.setState({ drawer: false })}}><img src={closeIcon} alt={siteTitle} width="24" height="24"  /></span></div>
          <div
            tabIndex={0}
            role="button"
            class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"
            /*onClick={() => {this.setState({ drawer: false })}}*/
            
            onKeyDown={() => {
              this.setState({ drawer: false })
            }}
            style={{ backgroundColor: '#1e1e1e' }}
          >
            
            <List className={this.props.classes.list}>
              <ListItem key={1} button divider className={classes.mobiLinkIterm} style={{borderTop: '1px solid #282828', fill: '#282828'}}>
                {" "}
                <a href="https://www.metgetup.co.za" className={classes.paddingMobi} onClick={this.closeBtn}>
                  Home
                </a>{" "}
              </ListItem>
              <ListItem key={1} button divider className={classes.mobiLinkIterm} style={{borderTop: '1px solid #282828', fill: '#282828'}} onClick={this.showMenu}>
                {" "}
                <div href="https://www.metgetup.co.za" className={classes.paddingMobi} style={{float: 'right'}}>
                Products 
                </div>
                <div style={{float: 'left'}}><i className="arrow down"></i></div>{" "}
              </ListItem>
              <div>
              {
          this.state.showMenu
            ? (
              <div id="i-want-this-to-collapse" class="collapse navbar-collapse" style={{marginTop: 20, height: 260}}>
              <nav className="nav  navbar-nav navbar-right visible-xs-block">
             
              <ListItem key={1} button divider className={`nav__menu-item ${classes.mobiLinkPrdct}`} >
      
            { this.state.showAboutMenu && <SubmenuMobile /> }
      
          </ListItem>
         
          </nav>
          </div>
            )
            : (
              null
            )
        }</div>
              <ListItem key={1} button divider className={classes.mobiLinkIterm}>
                {" "}
                <a href="https://www.metgetup.co.za/blog" className={classes.paddingMobi} onClick={this.closeBtn}>
                Blog
                </a>{" "}
              </ListItem>
              <ListItem key={1} button divider className={classes.mobiLinkIterm}>
                {" "}
                <a href="https://www.metgetup.co.za/claims" className={classes.paddingMobi} onClick={this.closeBtn}>
                Claims
                </a>{" "}
              </ListItem>
              <ListItem key={1} button divider className={classes.mobiLinkIterm}>
                {" "}
                <a href="https://metrohelp.zendesk.com/hc/en-us"  className={classes.paddingMobi} rel="noopener norefferer"> Get help </a>{" "}
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    )
  }

  //Larger Screens
  destroyDrawer() {
    const { classes, siteTitle } = this.props
    return (
    
         
      <React.Fragment>
      <CssBaseline />
      
      <AppBar style={{ backgroundColor: 'hsla(0,0%,100%,.5)'}} className={`${this.state.activeClass}`}>
        <Toolbar>
        <Container maxWidth="lg">
        <Grid container className={classes.mainGrid}>
          <Typography style={{ flexGrow: 1 }}  className="logo">
          <a href="https://www.metgetup.co.za" className={classes.padding} activeClassName="active"><img src={logo} alt={siteTitle} width="auto" height="59"  />
          </a>
          </Typography>
          <Typography className={classes.padding}>
            <a href="https://www.metgetup.co.za" className={classes.padding} activeClassName="active">
              Home
            </a>
          </Typography>
          <nav className="nav">
          <div className={`nav__menu-item ${classes.padding} ${this.state.navClass}`} onMouseLeave={this.handleLeave}>
            <Link onMouseEnter={this.handleHover} href="https://www.metgetup.co.za/products" className={`prodHead ${classes.padding} ${this.state.navClass}`}  activeClassName="active">
            Products <i className={`arrow down ${this.state.navClass}`}></i></Link>
            { this.state.showAboutMenu && <Submenu /> }
          </div>
          </nav>
          <Typography className={classes.padding}>
            <a href="https://www.metgetup.co.za/blog" className={classes.padding} activeClassName="active">
              Blog
            </a>
          </Typography>
          
          <Typography className={classes.padding}>
            <a href="https://www.metgetup.co.za/products/claims" className={classes.padding} activeClassName="active">
              Claims
            </a>

          </Typography>
          { /*<Typography className={classes.padding}>
            <Link to={"/blog"} className={classes.padding}>
              Blog
            </Link>
            </Typography>
          <Typography className={classes.padding}>
            <Link to={"/contact-us"} className={classes.padding}>
              Contact us
            </Link>
            
          </Typography>*/}
          <Typography className={classes.padding}>
          <a href="https://metrohelp.zendesk.com/hc/en-us"  className={classes.padding} rel="noopener norefferer"> Get help </a>
            
          </Typography>
          </Grid>
          </Container>
        </Toolbar>
        
      </AppBar>
      
      </React.Fragment>
     
     
    )
  }

  render() {
    return (
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    )
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  siteTitle: PropTypes.string,
  siteDesc: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
  siteDesc: ``,
}

export default withStyles(styleSheet)(Navigation)
