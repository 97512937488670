import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BreakdownButtons from './subComponents/breakdownButtons.js';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import {  Paper } from "@material-ui/core";
import circle from "../../assets/images/circ.svg";
import arrowDownloadPlan from "../../assets/images/arrow_download_plan.svg";
import axios from "axios";


let API_URL = "";

const host = window && window.location && window.location.hostname;
if (host === "localhost") {
    API_URL = "https://api-dev.getup.metropolitan.co.za";
} else if (host === "develop.d110dgi2spjubq.amplifyapp.com" || host === "www.funeralplanner-dev.metgetup.co.za" || host==="funeral-calculater-met-redesign.d110dgi2spjubq.amplifyapp.com") {
    API_URL = "https://api-dev.getup.metropolitan.co.za";
} else if (host === "www.funeralplannermetgetup.co.za") {
    API_URL = "https://api.getup.metropolitan.co.za";
} else {
    API_URL = "https://api.getup.metropolitan.co.za";
}
{/*console.log("Host: " + host + "\nAPI_URL: " + API_URL);*/
}

const useStyles = makeStyles({
  listItemText: {
    marginBottom: 4,
    marginLeft: 0,
    width: 180,
  },
  listItemIcon: {
    marginBottom: 0,
    marginLeft: 0,
    width: 60,
  },
  listItemTotal: {
    marginBottom: 0,
    marginLeft: 100,
    width: 110,
  },
  listItemPrice: {
    marginLeft: 0,
  },
  container: {
    marginTop: 20,
 paddingLeft: 16,
    paddingRight: 16

  },
  funeralTotalsContainers: {
    display:'flex',
    flexDirection:'row',
    flexWrap:'nowrap',
    alignItems:'center'
  },
  breakdownHeader: {
    backgroundColor:'#09eae5',
    color:'#4353ff',
    fontSize:'18px',
    fontWeight: '650',
    display:'flex',
    alignItems:'center',
    borderRadius: '10px',
    paddingLeft:'20px',
    width:'100%',
    height:'40px',

  },
  breakdownAlternativeContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    height: '99px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginBottom: '10px'
  },
  doughnutContainer: {
    width: '180px',
    margin: 0,
  },
  totalsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'right',
    justifyContent: 'left',
      paddingLeft: 16,
    width: '100%',
    fontFamily: "'HindSiliguri-SemiBold', 'Hind Siliguri SemiBold', 'Hind Siliguri', sans-serif",
  },
  totalsText: {
  margin:0,
  width: '100%',
  flexBasis: '100%',
  fontSize: '12px',
  color: '#4353ff'
  },
  paddedDivider: {
    marginTop: 16,
    marginBottom: 16
  }
});

const Chart = ( props ) => {
  const classes = useStyles();
    const [id, setId] = useState("");


    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setId(params.get('id'));

        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    },[]);


    console.log("MY DATA VALUES PASSED IN " + id);
  const data = {
    datasets: [
      {
        // Set data to 1 if totals are 0 for placeholder chart
        data: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? [-1] : [props.prefuneral.total, props.funeral.total, props.postfuneral.total],
        backgroundColor: [
          // Set color to lightgray if totals are 0
          (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? 'lightgray' : "#09eae5",
          "#e5fe04",
          "#78f6a0"
         ],
         borderWidth: 4,
         borderColor: 'white',
         hoverBorderColor: "white",
      }
    ],
    // Set Name to No Data if totals are 0
    labels: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? ['No Data'] : ['Pre-Funeral', 'Funeral', 'Post-Funeral'],
  };


    const dataMet = {
        datasets: [
            {
                // Set data to 1 if totals are 0 for placeholder chart
                data: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? [-1] : [props.prefuneral.total, props.funeral.total, props.postfuneral.total],
                backgroundColor: [
                    // Set color to lightgray if totals are 0
                    (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? 'lightgray' : "rgba(67, 83, 255, 1)",
                    "rgba(255, 100, 0, 1)",
                    "rgba(255, 229, 0, 1)"
                ],
                borderWidth: 4,
                borderColor: 'white',
                hoverBorderColor: "white",
            }
        ],
        // Set Name to No Data if totals are 0
        labels: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? ['No Data'] : ['Pre-Funeral', 'Funeral', 'Post-Funeral'],
    };

  const graphOnly = {
  datasets: [
        {
          // Set data to 1 if totals are 0 for placeholder chart
          data: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? [-1] : [props.prefuneral.total, props.funeral.total, props.postfuneral.total],
          backgroundColor: [
            // Set color to lightgray if totals are 0
            (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? 'lightgray' : "#09eae5",
            "#e5fe04",
            "#78f6a0"
           ],
           borderWidth: 4,
           borderColor: 'white',
           hoverBorderColor: "white",
        }
      ],
       labels: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? ['No Data'] : ['Pre-Funeral', 'Funeral', 'Post-Funeral'],
  };

    const graphOnlyMET = {
        datasets: [
            {
                // Set data to 1 if totals are 0 for placeholder chart
                data: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? [-1] : [props.prefuneral.total, props.funeral.total, props.postfuneral.total],
                backgroundColor: [
                    // Set color to lightgray if totals are 0
                    (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? 'lightgray' : "rgba(67, 83, 255, 1)",
                    "rgba(255, 100, 0, 1)",
                    "rgba(255, 229, 0, 1)"
                ],
                borderWidth: 4,
                borderColor: 'white',
                hoverBorderColor: "white",
            }
        ],
        labels: (props.prefuneral.total === 0 && props.funeral.total === 0 && props.postfuneral.total === 0) ? ['No Data'] : ['Pre-Funeral', 'Funeral', 'Post-Funeral'],
    };

  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  const [isDesktop, setDesktop] = useState(window.innerWidth > 599);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 599);
  };


  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });



    const post = async () => {

        let breakdown = {
            Repatriation: props.prefuneral.repatCheck ? parseInt(props.prefuneral.repat) : 0,
            Undertaker: props.prefuneral.undertakerCheck ? parseInt(props.prefuneral.undertaker) : 0,
            Cremation: props.prefuneral.cremationCheck ? parseInt(props.prefuneral.cremation) : 0,
            Cemetery: props.prefuneral.cemeteryCheck ? parseInt(props.prefuneral.cemetery) : 0,
            Coffin: props.prefuneral.coffinCheck ? parseInt(props.prefuneral.coffin) : 0,
            Urn: props.prefuneral.urnCheck ? parseInt(props.prefuneral.urn) : 0,
            Arrangements: props.prefuneral.arrangementsCheck ? parseInt(props.prefuneral.arrangements) : 0,
            Livestock: props.prefuneral.livestockCheck ? parseInt(props.prefuneral.livestock) : 0,
            Clothes: props.prefuneral.clothesCheck ? parseInt(props.prefuneral.clothes) : 0,
            ServiceVenue: props.funeral.serviceVenueCheck ? parseInt(props.funeral.serviceVenue) : 0,
            Chairs: props.funeral.chairsCheck ? parseInt(props.funeral.chairs) : 0,
            Catering: props.funeral.cateringCheck ? parseInt(props.funeral.catering) : 0,
            FuneralService: props.funeral.funeServiceCheck ? parseInt(props.funeral.funeService) : 0,
            Flowers: props.funeral.flowersCheck ? parseInt(props.funeral.flowers) : 0,
            Programmes: props.funeral.programmesCheck ? parseInt(props.funeral.programmes) : 0,
            Music: props.funeral.musicCheck ? parseInt(props.funeral.music) : 0,
            Tombstone: props.postfuneral.tombCheck ? parseInt(props.postfuneral.tomb) : 0,
            UnveilCeremony: props.postfuneral.unveilCheck ? parseInt(props.postfuneral.unveil) : 0,
            CleansingCeremony: props.postfuneral.cleansCheck ? parseInt(props.postfuneral.cleans) : 0
        }
        console.log("SERVICE" + JSON.stringify(breakdown));
        axios({
            method: 'POST',
            url: API_URL + '/pdfbuilder/documents/funeralcalculatormet/stream',
            responseType: 'blob',
            data: breakdown,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res);
            const file = new Blob([res.data], {type: "application/pdf"});
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL, "_blank");
        })
            .catch((error) => {
                console.log(error);
            });
    }



    return (
    <div>
        {isDesktop? (
        <div>
            {(id=='met') ? (
            <React.Fragment>

           <Grid  direction="row"   className={"BreakdownHead"}>
               <span>Breakdown </span>
            </Grid>
                <Grid container   xs={12} style={{paddingLeft:"60px",fontSize:"14px"}}>
                    <Grid item container direction="column" xs={8} sm={2} spacing={0}>
                        <Grid item >
                            <Paper
                                className={'legendBlue'}
                                square
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={8} sm={2} spacing={0}>
                        <Grid item>Pre-funeral</Grid>
                    </Grid>
                    <Grid item container direction="column" xs={8} sm={2} spacing={0}>
                        <Grid item>
                            <Paper
                                   className={'legendOrange'}
                                square
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={8} sm={2} spacing={0}>
                        <Grid item>Funeral</Grid>
                    </Grid>
                    <Grid item container direction="column" xs={8} sm={2} spacing={0}>
                        <Grid item>
                            <Paper
                                className={'legendYellow'}
                                square
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={8} sm={2} spacing={0}>
                        <Grid item>Post-Funeral</Grid>
                    </Grid>
                </Grid>

            </React.Fragment>):("")}
          <Grid container direction="row"   alignItems="right">

            <Grid item xs={12} alignItems="right" >
                {/* Display Doughnut chart */}

                  <Doughnut

                      data= {(() => {
                          switch (id) {
                              case "met":    return dataMet;
                              default:      return data;
                          }
                      })()}

                  options={{
                      cutoutPercentage:70,
                       weight:5,

                      legend: {
                          display: false,
                      },

                    tooltips: {
                        callbacks: {
                            label: function(tooltipItems, data) {
                            const amount = formatNumber(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]);
                            if(amount===-1)
                                return data.labels[tooltipItems.index] + " : R0";
                            return data.labels[tooltipItems.index] + " : R" + amount;
                            }
                        },

                    },


                    responsive: true,
                    maintainAspectRatio: true,
                  }}
                  />

                </Grid>
                <Grid item xs className={"totalCostFuneralHeading"} >
                  {/*={10} sm={6} md={2} lg={8}*/}
                {/*display Totals container*/}
                  {/*style={{paddingLeft:'20px'}}*/}

                    {(id=='met') ? (
                <div className={"float-right-sm"} >
                  <span align="left" >
                  Total cost of funeral:</span><br></br>
                  <Typography align="left" className={"RandValue"}>
                  R{formatNumber(parseInt(props.prefuneral.total) + parseInt(props.funeral.total) + parseInt(props.postfuneral.total))}</Typography>

                    <Grid container spacing={1} >
                        <Grid item xs={6} md={5}  style={{ width: '50px' }}>
                            <div className={classes.funeralTotalsContainers} >
                                <div style={{width:'15px', height:'15px', backgroundColor:'rgba(67, 83, 255, 1)',  marginRight: '10px'}} />
                                {(id=='met') ? (
                                <Typography align="left" className={"TotalCostSubHeadings"}>
                                    Pre-funeral: </Typography>):(
                                    <Typography align="left" className={"TotalCostSubHeadings"}>
                                        Pre-funeral: </Typography>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6} md={4}  style={{ width: '50px'}}>
                            <span className={"textItem"}> R{formatNumber(parseInt(props.prefuneral.total))}</span>
                        </Grid>


                        <Grid item xs={6} md={5}  style={{ width: '50px' }}>
                            <div className={classes.funeralTotalsContainers}>
                                <div style={{width:'15px', height:'15px', backgroundColor:'rgba(255, 100, 0, 1)', marginRight: '10px'}}/>
                                <Typography align="left" className={"TotalCostSubHeadings"}>
                                    Funeral: </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={4}  style={{ width: '50px'}}>
                            <span className={"textItem"}> R{formatNumber(parseInt(props.funeral.total))}</span>
                        </Grid>

                        <Grid item xs={6} md={5}  style={{ width: '50px'}}>
                            <div className={classes.funeralTotalsContainers}>
                                <div style={{width:'15px', height:'15px', backgroundColor:'rgb(255, 229, 0)',  marginRight: '10px'}}/>
                                <Typography align="left" className={"TotalCostSubHeadings"}>
                                   Post-funeral: </Typography>

                            </div>
                        </Grid>
                        <Grid item xs={6} md={4}  style={{width: '50px'}}>
                            <span className={"textItem"}> R{formatNumber(parseInt(props.postfuneral.total))}</span>
                        </Grid>

                    </Grid>
                    <Grid container spacing={1} >
                    <div className={"downloadPlanB"}  onClick={post}>
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained productButton closeDisclaimerButton"
                            tabIndex="0" type="button"><span className="MuiButton-label-close">&nbsp; Download your plan <span
                             ><img src={circle} className={"circleArrow"} />
                                </span></span><span
                            className="MuiTouchRipple-root MuiButton-iconSizeMedium"></span></button>
                        <img src={arrowDownloadPlan} className={"arrowDownloadPlan"} />
                    </div>
                    </Grid>

                    {/*<div className={classes.funeralTotalsContainers}>
                        <div style={{width:'15px', height:'15px', backgroundColor:'rgba(67, 83, 255, 1)', marginRight:'8px',marginTop:'8px', lineHeight: '0.43'}}/>
                        <Typography align="left" className={"TotalCostSubHeadings"}>
                            Pre-funeral: <span className={"textItemsBlue"}> R{formatNumber(parseInt(props.prefuneral.total))}</span></Typography>
                    </div>
                    <div className={classes.funeralTotalsContainers}>
                        <div style={{width:'15px', height:'15px', backgroundColor:'rgba(255, 100, 0, 1)',  marginRight:'8px',marginTop:'8px'}}/>
                        <Typography align="left"  className={"TotalCostSubHeadings"}>
                            Funeral: <span className={"textItemsOrange"}> R{formatNumber(parseInt(props.funeral.total))}</span></Typography>
                    </div>
                    <div className={classes.funeralTotalsContainers}>
                        <div style={{width:'15px', height:'15px', backgroundColor:'rgba(255, 229, 0, 1)',  marginRight:'8px',marginTop:'8px'}}/>
                        <Typography align="left" className={"TotalCostSubHeadings"}>
                            Post-funeral: <span className={"textItemYellow"}> R{formatNumber(parseInt(props.postfuneral.total))}</span></Typography>
                    </div>
                    <div className={"downloadPlan"}>
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained productButton closeDisclaimerButton  funeralSelfServiceButton"
                            tabIndex="0" type="button"><span className="MuiButton-label-close">&nbsp; Download your plan<span
                            className=""><img src={circle} className={"circleArrow"} />
                                </span></span><span
                            className="MuiTouchRipple-root MuiButton-iconSizeMedium"></span></button>
                        <img src={arrowDownloadPlan} className={"arrowDownloadPlan"} />
                    </div>*/}



                </div> ):(
                        <div style={{paddingLeft:'20px'}}>
                            <Typography align="left" className={"text-total-cost"}>
                                TOTAL COST OF FUNERAL:</Typography>
                            <Typography align="left"  className={"text-total-cost"}>
                                R{formatNumber(parseInt(props.prefuneral.total) + parseInt(props.funeral.total) + parseInt(props.postfuneral.total))}</Typography>

                            <div className={classes.funeralTotalsContainers}>
                                <div style={{width:'15px', height:'15px', backgroundColor:'#09eae5', marginRight:'20px'}}  />
                                <Typography align="left" className={"text-total-cost"} >
                                    PRE-FUNERAL: R{formatNumber(parseInt(props.prefuneral.total))}</Typography>
                            </div>
                            <div className={classes.funeralTotalsContainers}>
                                <div style={{width:'15px', height:'15px', backgroundColor:'#e5fe04', marginRight:'20px'}}/>
                                <Typography align="left" className={"text-total-cost"}>
                                    FUNERAL: R{formatNumber(parseInt(props.funeral.total))}</Typography>
                            </div>
                            <div className={classes.funeralTotalsContainers}>
                                <div style={{width:'15px', height:'15px', backgroundColor:'#78f6a0', marginRight:'20px'}}/>
                                <Typography align="left"  className={"text-total-cost"}>
                                    POST-FUNERAL: R{formatNumber(parseInt(props.postfuneral.total))}</Typography>
                            </div>
                        </div>

                    )}
                </Grid>


              {(id!=='met') ? (
                  <React.Fragment>
            <Grid container direction="row" alignItems="flex-start" justify='left' className={classes.container}>

               <Typography className={classes.breakdownHeader}>Pre-Funeral</Typography>
              <Grid item xs={false} sm={1}/>
              <Grid item xs={12}>
                <List>

                  {/* Display each item for Pre-Funeral under Breakdown */}
                  {props.pref.map((row) => (
                     (row.cName === true)?
                    <ListItem  key={row.name + 1}>
                    <ListItem className={classes.listItemIcon} key={row.name + 2}>
                        <img id={11} src={row.icon} title='1' description='1' width={25} height={25} alt={row.title} key={row.name + "img"}/>
                    </ListItem>
                      <ListItemText className={classes.listItemText}
                        primary={row.title}
                        key={row.name + 3}
                      />
                      <ListItemText className={classes.listItemPrice}
                        primary={'R' + formatNumber(row.val)}
                        key={row.name + 4}
                      />
                    </ListItem> : <div></div>
                      ))}
                    {props.pref.every((item) => {return item.cName === false})? <Typography style={{paddingLeft: 20}}>No items selected for Pre-Funeral Event</Typography>:""}
                      {/* END */}
                </List>
              </Grid>
            </Grid>


            <Grid container direction="row" alignItems="center" className={classes.container}>
               <Typography align="left" className={classes.breakdownHeader} style={{backgroundColor:'#e5fe04'}}>Funeral</Typography>
              <Grid item xs={1} />
              <Grid item xs={12}>
                <List>

                {/* Display each item for Funeral under Breakdown */}
                {props.fune.map((row) => (
                    (row.cName === true)?
                    <ListItem  key={row.name + 1}>
                    <ListItem className={classes.listItemIcon} key={row.name + 2}> 
                        <img id={11} src={row.icon} title='1' description='1' width={25} height={25} alt="repatriation" key={row.name + "img"}  />
                    </ListItem>
                      <ListItemText className={classes.listItemText}
                        primary={row.title}
                        key={row.name + 3}
                      />
                      <ListItemText className={classes.listItemPrice}
                        primary={'R' + formatNumber(row.val)}
                        key={row.name + 4}
                      />
                    </ListItem>:<div></div>
                      ))}
                    {props.fune.every((item) => {return item.cName === false})? <Typography style={{paddingLeft: 20}}>No items selected for Funeral Event</Typography>:""}
                    {/* END */}
                </List>
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" className={classes.container}>
               <Typography align="left" className={classes.breakdownHeader} style={{backgroundColor:'#78f6a0'}}>Post-Funeral</Typography>

              <Grid item xs={12}>
                <List>

                {/* Display each item for Post-Funeral under Breakdown */}
                {props.postf.map((row) => (
                    (row.cName === true)?
                    <ListItem key={row.name + 1}>
                    <ListItem className={classes.listItemIcon} key={row.name + 2}> 
                        <img id={11} src={row.icon} title='1' description='1' width={25} height={25} alt="repatriation" key={row.name + "img"}/>
                    </ListItem>
                      <ListItemText className={classes.listItemText}
                        primary={row.title}
                        key={row.name + 3}
                      />
                      <ListItemText className={classes.listItemPrice}
                        primary={'R' + formatNumber(row.val)}
                        key={row.name + 4}
                      />
                    </ListItem>:<div></div>
                      ))}
                    {props.postf.every((item) => {return item.cName === false})? <Typography style={{paddingLeft: 20}}>No items selected for Post-Funeral Event</Typography>:""}

                </List>
              </Grid>
            </Grid>
                  </React.Fragment>)
                  :("")}

              <hr className={"mainDivider"}/>
             </Grid>
               {/* <Divider className={classes.paddedDivider}/>*/}

            {(id!='met') ? (
                <React.Fragment>
                <BreakdownButtons
                    // Pre-Funeral state and functions
                    prefuneral={props.prefuneral} handleCheckedPre={props.handleCheckedPre} handleTotalPre={props.handleTotalPre}
                    // Funeral state and functions
                    funeral={props.funeral} handleCheckedFune={props.handleCheckedFune} handleTotalFune={props.handleTotalFune}
                    // Post-Funeral state and functions
                    postfuneral={props.postfuneral} handleCheckedPost={props.handleCheckedPost} handleTotalPost={props.handleTotalPost}

                    // Pre/Post/funeral Data Objects
                    pref={props.pref} fune={props.fune} postf={props.postf}
                />
                </React.Fragment>)
                :("")}



        </div>


        ):(

        <div className={classes.breakdownAlternativeContainer}>
          <div className={classes.totalsContainer}>
            <h4 className={classes.totalsText}>TOTAL COST OF FUNERAL:</h4>
            <h3 className={classes.totalsText} style={{fontSize:'26px'}}>
              R{formatNumber(props.prefuneral.total + props.funeral.total + props.postfuneral.total)}</h3>
          </div>
        <div className={classes.doughnutContainer}>
            <Doughnut data={graphOnly}
                      data= {(() => {
                          switch (id) {
                              case "met":    return graphOnlyMET;
                              default:      return graphOnly;
                          }
                      })()}
              options={{
              legend: { display: false },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItems, data) {
                        const amount = formatNumber(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]);
                        if(amount===-1)
                            return data.labels[tooltipItems.index] + " : R0";
                        return data.labels[tooltipItems.index] + " : R" + amount;
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: true,
              }}
              />
        </div>


        </div>

        )}



    </div>

)
}

export default Chart
