import Container from "@material-ui/core/Container";
import {Grid, makeStyles, Paper} from "@material-ui/core";
import FooterBlueStrip from "../../../assets/images/stripfooter.svg";
import React from "react";
import "./MetFooter.css";
import loadable from '@loadable/component';
import MetCopyright from "../footer/MetCopyright";
const SocialAccounts = loadable(() => import('./socialAccounts'));
const Copyright = loadable(() => import("../footer/MetCopyright"));


const useStyles = makeStyles(theme => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/user/erondu)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },

}));


function MetFooter() {
    const classes = useStyles();

    return (
        <footer className="mainfooterstyle">
            <Container maxWidth="lg">
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={3} >
                        <div className="footer__menu-content js-component-footer-menu-toggle">
                            <span className={"footerHeading"}>HELP & SUPPORT</span>
                            <span><img src={FooterBlueStrip} width="160px"/></span>
                            <br/>
                            <div className="footer__menu-items js-component-footer-menu-content">
                                <a href="https://www.metropolitan.co.za/service-centre/findbranch/"  target={'_blank'} className="footer__menu-item">Find a branch</a>
                                <br/>  <a href="https://www.metropolitan.co.za/service-center/faq/"   target={'_blank'} className="footer__menu-item">Visit FAQs</a>
                                <br/>  <a href="https://www.metropolitan.co.za/contact-us/"   target={'_blank'} className="footer__menu-item">Contact Us</a>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div className="footer__menu-content js-component-footer-menu-toggle">
                            <span className={"footerHeading"}>METROPOLITAN PRODUCTS</span>

                            <span><img src={FooterBlueStrip} width="220px"/></span>
                            <br/>
                            <div className="footer__menu-items js-component-footer-menu-content">
                                <a href="https://www.metropolitan.co.za/funeral/" target="_blank"
                                   rel="noopener noreferrer" className="footer__menu-item">Metropolitan funeral plan</a>
                                <br/>
                                <a href="https://www.metropolitan.co.za/life-health/lifecover/" target="_blank"
                                   rel="noopener noreferrer" className="footer__menu-item">Life & health cover
                                    plans</a>
                                <br/>
                                <a href="https://www.metropolitan.co.za/save-and-invest/savings-plans/" target="_blank"
                                   rel="noopener noreferrer" className="footer__menu-item">Savings, income & investment
                                    plans</a>
                                <br/>
                                <a href="https://www.metropolitan.co.za/retirement/savings-plans/" target="_blank"
                                   rel="noopener noreferrer" className="footer__menu-item">Retirement plans</a><br/>

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} width="600px">
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className={`footerBar  ${classes.paper}`}>
                            <div className="footer__contact">
                                <i className="footer__contact-icon" data-icon="contact"></i>
                                <div className="footer__contact-title">Contact us</div>
                                <div className="footer__contact-number">0860 724 724</div>
                                <a href="mailto:info@metropolitan.co.za" className="footer_contact-email">
                                    <i className="footer__contact-icon footer" data-icon="mail_outline"></i>
                                    <div className="footer__contact-title footer_title"> Email us</div>
                                </a>
                                <div className="footer__social">
                                    <a href="https://www.facebook.com/MetropolitanZA" className="footer__social-item"
                                       target="_blank" aria-label="facebook">
                                        <i className="footer__social-item-icon" data-icon="social-facebook"></i>
                                    </a>
                                    <a href="https://www.instagram.com/metropolitan_za/" className="footer__social-item"
                                       target="_blank" aria-label="instagram">
                                        <i className="footer__social-item-icon" data-icon="social-instagram"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/metropolitan-life?trk=prof-exp-company-name"
                                       className="footer__social-item" target="_blank" aria-label="linkedin">
                                        <i className="footer__social-item-icon" data-icon="social-linkedin"></i>
                                    </a>
                                    <a href="https://twitter.com/MetropolitanZA" className="footer__social-item"
                                       target="_blank" aria-label="twitter">
                                        <i className="footer__social-item-icon" data-icon="social-twitter"></i>
                                    </a>
                                    <a href="https://www.youtube.com/c/MetropolitanZA" className="footer__social-item"
                                       target="_blank" aria-label="youtube">
                                        <i className="footer__social-item-icon" data-icon="social-youtube"></i>
                                    </a>
                                </div>
                            </div>

                        </Paper>
                    </Grid>

                    <Grid item xs={12}>

                        <MetCopyright />

                    </Grid>
                </Grid>



            </Container>

        </footer>
    );
}

export default MetFooter
