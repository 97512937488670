import Container from "@material-ui/core/Container";
import {Grid, makeStyles, Paper} from "@material-ui/core";
import {Link, Redirect} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import React from "react";
import "./GetUpFooter.css";
import SocialAccounts from './socialAccounts';
import Copyright from "./Copyright";


const useStyles = makeStyles(theme => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/user/erondu)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
    footer: {
        backgroundColor: '#2d2d2d',
        marginTop: theme.spacing(0),
        padding: theme.spacing(6, 0),
        height: 'auto',
        color: '#fff',
        fontSize: 10,
    },
}));
function GetUpFooter() {
    const classes = useStyles();

    return (
        <footer className="mainfooterstyle">

           {/* <Container maxWidth="lg" >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} >
                        <Paper className="footerBar footerCopy">
                            <h3>OUR PRODUCTS</h3>
                            <a href="https://master.d366ccfdvyj2mb.amplifyapp.com/product/free-credit-report" target="_blank"><span className="fSize14">Free credit report</span></a><br/>
                            <a href="https://master.d366ccfdvyj2mb.amplifyapp.com//product/debts-consolidation-loan" target="_blank">Debt consolidation loan</a><br/>
                            <a href="https://master.d366ccfdvyj2mb.amplifyapp.com//product/funeral-cover" target="_blank">Funeral cover</a><br/>
                            <a href="https://master.d366ccfdvyj2mb.amplifyapp.com//product/your-education-life-goal" target="_blank">Your education life goal</a><br/>
                            <a href="https://master.d366ccfdvyj2mb.amplifyapp.com//product/metropolitan-savings-plan" target="_blank">Savings plan</a><br/>


                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className="footerBar footerCopy">
                            <h3>RESOURCES</h3>
                            <a href="https://master.d366ccfdvyj2mb.amplifyapp.com/privacy-policy" target="_blank">Privacy notice</a>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <Paper className="footerBar footerCopy">
                            <h3>OUR FAMILY</h3>
                            <a href="https://www.momentummetropolitan.co.za/en" target="_blank">MMH</a><br/>
                            <a href="https://www.metropolitan.co.za" target="_blank">Metropolitan</a>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className="footerBar footerCopy">
                            <h3>ABOUT GETUP</h3>
                            <a href="https://metrohelp.zendesk.com/hc/en-us" target="_blank">About us</a><br/>
                            <a href="https://metrohelp.zendesk.com/hc/en-us" target="_blank">Get help</a><br/><br/>
                            <SocialAccounts />

                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className="footerBar footerCopy">
                            <Typography  className="copyrightStyle">
                                Metropolitan GetUp is part of Momentum Metropolitan Life Limited, an authorised financial service (FSP 44673) and registered credit provider (NCRCP173), and rated B-BBEE level 1
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>



            </Container>*/}


            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} >
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>OUR PRODUCTS</h3>
                            <a href={`https://www.metgetup.co.za/product/free-credit-report`}>Free credit report</a><br/>
                            <a href={`https://www.metgetup.co.za/product/debts-consolidation-loan`}>Debt consolidation loan</a><br/>
                            <a href={`https://www.metgetup.co.za/product/income-protection`}>Income Protection</a><br/>
                            <a href={`https://www.metgetup.co.za/product/funeral-cover`}>Funeral cover</a><br/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>RESOURCES</h3>
                            <a href={`https://www.metgetup.co.za/privacy-policy`}>Privacy notice</a><br/>
                            <a href={`https://www.metgetup.co.za/terms-of-use`}>Terms of use</a><br/>
                            <a href={`https://www.metgetup.co.za/transacting-online`}>Transacting Online</a><br/>
                            <a href={`https://sacoronavirus.co.za`} target="_blank" rel="noopener">Covid-19 resource centre</a>

                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>OUR FAMILY</h3>
                            <a href={`https://www.momentummetropolitan.co.za/en`} target="_blank" rel="noopener">Momentum Metropolitan Holdings</a><br/>
                            <a href={`https://www.metropolitan.co.za`} target="_blank" rel="noopener">Metropolitan</a>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <h3>ABOUT GETUP</h3>
                            <a href={`https://www.metgetup.co.za/aboutus`} replace>About us</a><br/>
                            <a href={`https://metrohelp.zendesk.com/hc/en-us`} rel="noopener" replace>Get help</a><br/><br/>
                            <SocialAccounts />

                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={`footerBar footerCopy ${classes.paper}`}>
                            <Copyright />
                        </Paper>
                    </Grid>
                </Grid>



            </Container>

        </footer>
    );
}

export default GetUpFooter
